import { ReactComponent as AppLogo } from 'src/assets/icons/app-logo.svg';
import { ReactComponent as UserPlaceholderLarge } from 'src/assets/icons/L/user-placeholder.svg';

import { ReactComponent as AllM } from 'src/assets/icons/M/all-m.svg';
import { ReactComponent as AllS } from 'src/assets/icons/S/all-s.svg';
import { ReactComponent as ArrowDownM } from 'src/assets/icons/M/arrow-down-m.svg';
import { ReactComponent as SoonS } from 'src/assets/icons/S/soon-s.svg';
import { ReactComponent as ArrowDownS } from 'src/assets/icons/S/arrow-down-s.svg';
import { ReactComponent as ArrowLeftM } from 'src/assets/icons/M/arrow-left-m.svg';
import { ReactComponent as ArrowLeftS } from 'src/assets/icons/S/arrow-left-s.svg';
import { ReactComponent as ArrowRightM } from 'src/assets/icons/M/arrow-right-m.svg';
import { ReactComponent as ArrowRightS } from 'src/assets/icons/S/arrow-right-s.svg';
import { ReactComponent as ArrowUpM } from 'src/assets/icons/M/arrow-up-m.svg';
import { ReactComponent as ArrowUpS } from 'src/assets/icons/S/arrow-up-s.svg';
import { ReactComponent as AvatarM } from 'src/assets/icons/M/avatar-m.svg';
import { ReactComponent as AvatarS } from 'src/assets/icons/S/avatar-s.svg';
import { ReactComponent as AvaxM } from 'src/assets/icons/M/avax-m.svg';
import { ReactComponent as AvaxS } from 'src/assets/icons/S/avax-s.svg';
import { ReactComponent as BnbM } from 'src/assets/icons/M/bnb-m.svg';
import { ReactComponent as BnbS } from 'src/assets/icons/S/bnb-s.svg';
import { ReactComponent as BoxM } from 'src/assets/icons/M/box-m.svg';
import { ReactComponent as BoxS } from 'src/assets/icons/S/box-s.svg';
import { ReactComponent as BtcM } from 'src/assets/icons/M/btc-m.svg';
import { ReactComponent as BtcS } from 'src/assets/icons/S/BTC-s.svg';
import { ReactComponent as BurnM } from 'src/assets/icons/M/burn-m.svg';
import { ReactComponent as BurnS } from 'src/assets/icons/S/burn-s.svg';
import { ReactComponent as CalendarM } from 'src/assets/icons/M/calendar-m.svg';
import { ReactComponent as CalendarS } from 'src/assets/icons/S/calendar-s.svg';
import { ReactComponent as ChampionM } from 'src/assets/icons/M/champion-m.svg';
import { ReactComponent as ChampionS } from 'src/assets/icons/S/champion-s.svg';
import { ReactComponent as CheckM } from 'src/assets/icons/M/check-m.svg';
import { ReactComponent as CheckS } from 'src/assets/icons/S/check-s.svg';
import { ReactComponent as CheckBoxM } from 'src/assets/icons/M/check-box-m.svg';
import { ReactComponent as CheckBoxS } from 'src/assets/icons/S/check-box-s.svg';
import { ReactComponent as CheckBoxCheckedM } from 'src/assets/icons/M/check-box-checked-m.svg';
import { ReactComponent as CheckBoxCheckedS } from 'src/assets/icons/S/check-box-checked-s.svg';
import { ReactComponent as ClockM } from 'src/assets/icons/M/clock-m.svg';
import { ReactComponent as ClockS } from 'src/assets/icons/S/clock-s.svg';
import { ReactComponent as CloseM } from 'src/assets/icons/M/close-m.svg';
import { ReactComponent as CloseS } from 'src/assets/icons/S/close-s.svg';
import { ReactComponent as ComingSoonM } from 'src/assets/icons/M/coming-soon-m.svg';
import { ReactComponent as ComingSoon2M } from 'src/assets/icons/M/coming-soon2-m.svg';
import { ReactComponent as ComingSoonS } from 'src/assets/icons/S/coming-soon-s.svg';
import { ReactComponent as CommonM } from 'src/assets/icons/M/common-m.svg';
import { ReactComponent as CommonS } from 'src/assets/icons/S/common-s.svg';
import { ReactComponent as CopyM } from 'src/assets/icons/M/copy-m.svg';
import { ReactComponent as CopyS } from 'src/assets/icons/S/copy-s.svg';
import { ReactComponent as DiceM } from 'src/assets/icons/M/dice-m.svg';
import { ReactComponent as DiceS } from 'src/assets/icons/S/dice-s.svg';
import { ReactComponent as DiscordM } from 'src/assets/icons/M/discord-m.svg';
import { ReactComponent as DiscordS } from 'src/assets/icons/S/discord-s.svg';
import { ReactComponent as DollarM } from 'src/assets/icons/M/dollar-m.svg';
import { ReactComponent as DollarS } from 'src/assets/icons/S/dollar-s.svg';
import { ReactComponent as DownloadM } from 'src/assets/icons/M/download-m.svg';
import { ReactComponent as DownloadS } from 'src/assets/icons/S/download-s.svg';
import { ReactComponent as EditM } from 'src/assets/icons/M/edit-m.svg';
import { ReactComponent as EditS } from 'src/assets/icons/S/edit-s.svg';
import { ReactComponent as EpicM } from 'src/assets/icons/M/epic-m.svg';
import { ReactComponent as EpicS } from 'src/assets/icons/S/epic-s.svg';
import { ReactComponent as EthM } from 'src/assets/icons/M/eth-m.svg';
import { ReactComponent as EthS } from 'src/assets/icons/S/eth-s.svg';
import { ReactComponent as EyeM } from 'src/assets/icons/M/eye-m.svg';
import { ReactComponent as EyeS } from 'src/assets/icons/S/eye-s.svg';
import { ReactComponent as EyeClosedM } from 'src/assets/icons/M/eye-closed-m.svg';
import { ReactComponent as EyeClosedS } from 'src/assets/icons/S/eye-closed-s.svg';
import { ReactComponent as FileM } from 'src/assets/icons/M/file-m.svg';
import { ReactComponent as FileS } from 'src/assets/icons/S/file-s.svg';
import { ReactComponent as FinishedM } from 'src/assets/icons/M/finished-m.svg';
import { ReactComponent as FinishedS } from 'src/assets/icons/S/finished-s.svg';
import { ReactComponent as GiftM } from 'src/assets/icons/M/gift-m.svg';
import { ReactComponent as GiftS } from 'src/assets/icons/S/gift-s.svg';
import { ReactComponent as GridM } from 'src/assets/icons/M/grid-m.svg';
import { ReactComponent as GridS } from 'src/assets/icons/S/grid-s.svg';
import { ReactComponent as HappyM } from 'src/assets/icons/M/happy-m.svg';
import { ReactComponent as HappyS } from 'src/assets/icons/S/happy-s.svg';
import { ReactComponent as HeartM } from 'src/assets/icons/M/heart-m.svg';
import { ReactComponent as HeartS } from 'src/assets/icons/S/heart-s.svg';
import { ReactComponent as HomeM } from 'src/assets/icons/M/home-m.svg';
import { ReactComponent as HomeS } from 'src/assets/icons/S/home-s.svg';
import { ReactComponent as HyperspaceM } from 'src/assets/icons/M/hyperspace-m.svg';
import { ReactComponent as HyperspaceS } from 'src/assets/icons/S/hyperspace-s.svg';
import { ReactComponent as KeyM } from 'src/assets/icons/M/key-m.svg';
import { ReactComponent as KeyS } from 'src/assets/icons/S/key-s.svg';
import { ReactComponent as LegendaryM } from 'src/assets/icons/M/legendary-m.svg';
import { ReactComponent as LegendaryS } from 'src/assets/icons/S/legendary-s.svg';
import { ReactComponent as ListM } from 'src/assets/icons/M/list-m.svg';
import { ReactComponent as ListS } from 'src/assets/icons/S/list-s.svg';
import { ReactComponent as LiveM } from 'src/assets/icons/M/live-m.svg';
import { ReactComponent as LiveS } from 'src/assets/icons/S/live-s.svg';
import { ReactComponent as LockM } from 'src/assets/icons/M/lock-m.svg';
import { ReactComponent as LockS } from 'src/assets/icons/S/lock-s.svg';
import { ReactComponent as LoginM } from 'src/assets/icons/M/login-m.svg';
import { ReactComponent as LoginS } from 'src/assets/icons/S/login-s.svg';
import { ReactComponent as LogoutM } from 'src/assets/icons/M/logout-m.svg';
import { ReactComponent as LogoutS } from 'src/assets/icons/S/logout-s.svg';
import { ReactComponent as MaticM } from 'src/assets/icons/M/matic-m.svg';
import { ReactComponent as MaticS } from 'src/assets/icons/S/matic-s.svg';
import { ReactComponent as MintM } from 'src/assets/icons/M/mint-m.svg';
import { ReactComponent as Mint2M } from 'src/assets/icons/M/mint2-m.svg';
import { ReactComponent as MintS } from 'src/assets/icons/S/mint-s.svg';
import { ReactComponent as MinusM } from 'src/assets/icons/M/minus-m.svg';
import { ReactComponent as MinusS } from 'src/assets/icons/S/minus-s.svg';
import { ReactComponent as MoonM } from 'src/assets/icons/M/moon-m.svg';
import { ReactComponent as MoonS } from 'src/assets/icons/S/moon-s.svg';
import { ReactComponent as MythicalM } from 'src/assets/icons/M/mythical-m.svg';
import { ReactComponent as MythicalS } from 'src/assets/icons/S/mythical-s.svg';
import { ReactComponent as NotificationM } from 'src/assets/icons/M/notification-m.svg';
import { ReactComponent as NotificationS } from 'src/assets/icons/S/notification-s.svg';
import { ReactComponent as PlaceholderM } from 'src/assets/icons/M/placeholder-m.svg';
import { ReactComponent as PlaceholderS } from 'src/assets/icons/S/placeholder-s.svg';
import { ReactComponent as PlusM } from 'src/assets/icons/M/plus-m.svg';
import { ReactComponent as PlusS } from 'src/assets/icons/S/plus-s.svg';
import { ReactComponent as PoolM } from 'src/assets/icons/M/pool-m.svg';
import { ReactComponent as PoolS } from 'src/assets/icons/S/pool-s.svg';
import { ReactComponent as RareM } from 'src/assets/icons/M/rare-m.svg';
import { ReactComponent as RareS } from 'src/assets/icons/S/rare-s.svg';
import { ReactComponent as RedeemM } from 'src/assets/icons/M/redeem-m.svg';
import { ReactComponent as RedeemS } from 'src/assets/icons/S/redeem-s.svg';
import { ReactComponent as RocketM } from 'src/assets/icons/M/rocket-m.svg';
import { ReactComponent as RocketS } from 'src/assets/icons/S/rocket-s.svg';
import { ReactComponent as SaS } from 'src/assets/icons/S/sa-s.svg';
import { ReactComponent as SadM } from 'src/assets/icons/M/sad-m.svg';
import { ReactComponent as SearchM } from 'src/assets/icons/M/search-m.svg';
import { ReactComponent as SearchS } from 'src/assets/icons/S/search-s.svg';
import { ReactComponent as SettingsM } from 'src/assets/icons/M/settings-m.svg';
import { ReactComponent as SettingsS } from 'src/assets/icons/S/settings-s.svg';
import { ReactComponent as SolM } from 'src/assets/icons/M/sol-m.svg';
import { ReactComponent as SolS } from 'src/assets/icons/S/sol-s.svg';
import { ReactComponent as StarM } from 'src/assets/icons/M/star-m.svg';
import { ReactComponent as StarS } from 'src/assets/icons/S/star-s.svg';
import { ReactComponent as SuiM } from 'src/assets/icons/M/sui-m.svg';
import { ReactComponent as SuiS } from 'src/assets/icons/S/sui-s.svg';
import { ReactComponent as SunM } from 'src/assets/icons/M/sun-m.svg';
import { ReactComponent as SunS } from 'src/assets/icons/S/sun-s.svg';
import { ReactComponent as TrashM } from 'src/assets/icons/M/trash-m.svg';
import { ReactComponent as TrashS } from 'src/assets/icons/S/trash-s.svg';
import { ReactComponent as TwitterM } from 'src/assets/icons/M/twitter-m.svg';
import { ReactComponent as TwitterS } from 'src/assets/icons/S/twitter-s.svg';
import { ReactComponent as UncommonM } from 'src/assets/icons/M/uncommon-m.svg';
import { ReactComponent as UncommonS } from 'src/assets/icons/S/uncommon-s.svg';
import { ReactComponent as UnlockM } from 'src/assets/icons/M/unlock-m.svg';
import { ReactComponent as UnlockS } from 'src/assets/icons/S/unlock-s.svg';
import { ReactComponent as UserM } from 'src/assets/icons/M/user-m.svg';
import { ReactComponent as UserS } from 'src/assets/icons/S/user-s.svg';
import { ReactComponent as UsersM } from 'src/assets/icons/M/users-m.svg';
import { ReactComponent as UsersS } from 'src/assets/icons/S/users-s.svg';
import { ReactComponent as WalletM } from 'src/assets/icons/M/wallet-m.svg';
import { ReactComponent as WalletS } from 'src/assets/icons/S/wallet-s.svg';
import { ReactComponent as WebM } from 'src/assets/icons/M/web-m.svg';
import { ReactComponent as WebS } from 'src/assets/icons/S/web-s.svg';
import { ReactComponent as QiibeeLogo } from 'src/assets/icons/L/qiibee-logo.svg';
import { ReactComponent as UserPlaceholderXl } from 'src/assets/icons/XL/user-placeholder-xl.svg';
import { ReactComponent as Live2M } from 'src/assets/icons/M/live2-m.svg';
import { ReactComponent as Expired } from 'src/assets/icons/M/expired.svg';
import { ReactComponent as MintBtnS } from 'src/assets/icons/S/mint-button-s.svg';
import { ReactComponent as MintBtnM } from 'src/assets/icons/M/mint-button-m.svg';
import { ReactComponent as OpenBoxBtnS } from 'src/assets/icons/S/openbox-button-s.svg';
import { ReactComponent as OpenBoxBtnM } from 'src/assets/icons/M/openbox-button-m.svg';
import { ReactComponent as TicketM } from 'src/assets/icons/M/ticket-m.svg';
import { ReactComponent as LogoS } from 'src/assets/icons/S/logo-s.svg';
import { ReactComponent as createM } from 'src/assets/icons/M/create-m.svg';
import { ReactComponent as ProfilePlaceholder } from 'src/assets/icons/profile-placeholder.svg';
import { ReactComponent as ReloadS } from 'src/assets/icons/S/reload-s.svg';
import { ReactComponent as ExternalLinkS } from 'src/assets/icons/S/external-link-s.svg';
import { ReactComponent as egldCurrency } from 'src/assets/icons/S/egld.svg';
import { ReactComponent as egldS } from 'src/assets/icons/S/egld-s.svg';
import { ReactComponent as CheckmarkConnected } from 'src/assets/icons/M/check-mark-connected.svg';
import { ReactComponent as RectangleS } from 'src/assets/icons/S/rectangle-s.svg';
import { ReactComponent as LeaderboardS } from 'src/assets/icons/S/leaderboard-s.svg';
import { ReactComponent as MenuM } from 'src/assets/icons/M/menu-m.svg';
import { ReactComponent as UserGroupM } from 'src/assets/icons/M/user-group-m.svg';

interface IconSvgProps {
  icon: Icons;
  className?: string;
  [key: string]: any;
}

const icons = Object.freeze({
  appLogo: AppLogo,
  qiibeeLogo: QiibeeLogo,
  userPlaceholderLarge: UserPlaceholderLarge,
  allM: AllM,
  allS: AllS,
  soonS: SoonS,
  arrowDownM: ArrowDownM,
  arrowDownS: ArrowDownS,
  arrowLeftM: ArrowLeftM,
  arrowLeftS: ArrowLeftS,
  arrowRightM: ArrowRightM,
  arrowRightS: ArrowRightS,
  arrowUpM: ArrowUpM,
  arrowUpS: ArrowUpS,
  avatarM: AvatarM,
  avatarS: AvatarS,
  avaxM: AvaxM,
  avaxS: AvaxS,
  bnbM: BnbM,
  bnbS: BnbS,
  boxM: BoxM,
  boxS: BoxS,
  btcM: BtcM,
  btcS: BtcS,
  burnM: BurnM,
  burnS: BurnS,
  calendarM: CalendarM,
  calendarS: CalendarS,
  championM: ChampionM,
  championS: ChampionS,
  checkM: CheckM,
  checkS: CheckS,
  checkBoxM: CheckBoxM,
  checkBoxS: CheckBoxS,
  checkBoxCheckedM: CheckBoxCheckedM,
  checkBoxCheckedS: CheckBoxCheckedS,
  clockM: ClockM,
  clockS: ClockS,
  closeM: CloseM,
  closeS: CloseS,
  comingSoonM: ComingSoonM,
  comingSoon2M: ComingSoon2M,
  comingSoonS: ComingSoonS,
  commonM: CommonM,
  commonS: CommonS,
  copyM: CopyM,
  egldS: egldS,
  copyS: CopyS,
  diceM: DiceM,
  diceS: DiceS,
  discordM: DiscordM,
  discordS: DiscordS,
  dollarM: DollarM,
  dollarS: DollarS,
  downloadM: DownloadM,
  downloadS: DownloadS,
  editM: EditM,
  editS: EditS,
  epicM: EpicM,
  epicS: EpicS,
  ethM: EthM,
  ethS: EthS,
  eyeM: EyeM,
  eyeS: EyeS,
  eyeClosedM: EyeClosedM,
  eyeClosedS: EyeClosedS,
  fileM: FileM,
  fileS: FileS,
  finishedM: FinishedM,
  finishedS: FinishedS,
  giftM: GiftM,
  giftS: GiftS,
  gridM: GridM,
  gridS: GridS,
  happyM: HappyM,
  happyS: HappyS,
  heartM: HeartM,
  heartS: HeartS,
  homeM: HomeM,
  homeS: HomeS,
  hyperspaceM: HyperspaceM,
  hyperspaceS: HyperspaceS,
  keyM: KeyM,
  keyS: KeyS,
  legendaryM: LegendaryM,
  legendaryS: LegendaryS,
  listM: ListM,
  listS: ListS,
  liveM: LiveM,
  live2M: Live2M,
  expired: Expired,
  liveS: LiveS,
  lockM: LockM,
  lockS: LockS,
  loginM: LoginM,
  loginS: LoginS,
  logoutM: LogoutM,
  logoutS: LogoutS,
  maticM: MaticM,
  maticS: MaticS,
  mintM: MintM,
  mint2M: Mint2M,
  mintS: MintS,
  minusM: MinusM,
  minusS: MinusS,
  moonM: MoonM,
  moonS: MoonS,
  mythicalM: MythicalM,
  mythicalS: MythicalS,
  notificationM: NotificationM,
  notificationS: NotificationS,
  placeholderM: PlaceholderM,
  placeholderS: PlaceholderS,
  egld: egldCurrency,
  plusM: PlusM,
  plusS: PlusS,
  poolM: PoolM,
  poolS: PoolS,
  rareM: RareM,
  rareS: RareS,
  redeemM: RedeemM,
  redeemS: RedeemS,
  rocketM: RocketM,
  rocketS: RocketS,
  saS: SaS,
  sadM: SadM,
  searchM: SearchM,
  searchS: SearchS,
  settingsM: SettingsM,
  settingsS: SettingsS,
  solM: SolM,
  solS: SolS,
  starM: StarM,
  starS: StarS,
  suiM: SuiM,
  suiS: SuiS,
  sunM: SunM,
  sunS: SunS,
  trashM: TrashM,
  trashS: TrashS,
  twitterM: TwitterM,
  twitterS: TwitterS,
  uncommonM: UncommonM,
  uncommonS: UncommonS,
  unlockM: UnlockM,
  unlockS: UnlockS,
  userM: UserM,
  userS: UserS,
  usersM: UsersM,
  usersS: UsersS,
  walletM: WalletM,
  walletS: WalletS,
  webM: WebM,
  webS: WebS,
  userPlaceholderXl: UserPlaceholderXl,
  mintBtnS: MintBtnS,
  mintBtnM: MintBtnM,
  openBoxBtnS: OpenBoxBtnS,
  openBoxBtnM: OpenBoxBtnM,
  ticketM: TicketM,
  logoS: LogoS,
  createM: createM,
  profilePlaceholder: ProfilePlaceholder,
  reloadS: ReloadS,
  externalLinkS: ExternalLinkS,
  checkmarkConnected: CheckmarkConnected,
  rectangleS: RectangleS,
  leaderboardS: LeaderboardS,
  menuM: MenuM,
  userGroupM: UserGroupM,
});

export type Icons = keyof typeof icons;

const IconSvg = ({ icon, className, ...rest }: IconSvgProps) => {
  const SvgIcon = icons[icon];
  return <SvgIcon className={className} {...rest} />;
};

export default IconSvg;
