import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { useState } from 'react';
import IconSvg, { Icons } from './ui/IconSvg';
interface PoolSelectProps {
  onChange?: (selectedPool: { id: number; name: string; icon: Icons }) => void;
}
  export default function PoolSelect({ onChange }: PoolSelectProps) {

  const [pools] = useState<{ id: number; name: string; icon: Icons }[]>([
    { id: 1, name: 'All pools', icon: 'allM' },
    { id: 2, name: 'Common pools', icon: 'commonM' },
    { id: 3, name: 'Uncommon pools', icon: 'uncommonM' },
    { id: 4, name: 'Rare pools', icon: 'rareM' },
    { id: 5, name: 'Epic pools', icon: 'epicM' },
    { id: 6, name: 'Legendary pools', icon: 'legendaryM' },
    { id: 7, name: 'Mythical pools', icon: 'mythicalM' },
  ]);

  const [selectedPool, setSelectedPool] = useState(pools[0]);

  const handleChange = (pool: { id: number; name: string; icon: Icons }) => {
    setSelectedPool(pool);
    if (onChange) onChange(pool); // Safely call onChange if provided
  };
  

  return (
    <Listbox value={selectedPool} onChange={handleChange}>
      <ListboxButton
        className={`group w-[270px] bg-grayscale-800 flex justify-between items-center py-3 px-4 rounded border border-transparent data-[open]:border-grayscale-600`}
      >
        <div className="inline-flex items-center gap-2">
          <IconSvg icon={selectedPool.icon} className="text-gray-200" />

          <span className="text-sm text-grayscale-200">{selectedPool.name}</span>
        </div>

        <IconSvg
          icon="arrowDownM"
          className="text-brand-primary-light group-data-[open]:rotate-180 transition-all duration-300"
        />
      </ListboxButton>

      <ListboxOptions
        anchor={{ to: 'bottom', gap: -3 }}
        className={`group z-10 w-[var(--button-width)] bg-grayscale-800 rounded cursor-pointer border border-transparent data-[open]:border-grayscale-600 data-[anchor=bottom_center]:border-t-0 data-[anchor=top_center]:border-b-0 data-[anchor=bottom_center]:rounded-t-none data-[anchor=top_center]:rounded-b-none`}
      >
        {/* Selected item as last option */}
        <div className="hidden group-data-[anchor=top_center]:block">
          <ListboxOption
            key={selectedPool.id}
            value={selectedPool}
            className={`inline-flex items-center py-3 px-4 gap-2`}
          >
            <IconSvg icon={selectedPool.icon} className="text-gray-200" />

            <span className="text-sm text-grayscale-200">{selectedPool.name}</span>
          </ListboxOption>
        </div>

        {pools
          .filter(pool => pool.id !== selectedPool.id)
          .map(person => (
            <ListboxOption
              key={person.id}
              value={person}
              className={`inline-flex items-center py-3 px-4 gap-2`}
            >
              <IconSvg icon={person.icon} className="text-gray-200" />

              <span className="text-sm text-grayscale-200">{person.name}</span>
            </ListboxOption>
          ))}

        {/* Selected item as last option */}
        <div className="hidden group-data-[anchor=bottom_center]:block">
          <ListboxOption
            key={selectedPool.id}
            value={selectedPool}
            className={`inline-flex items-center py-3 px-4 gap-2`}
          >
            <IconSvg icon={selectedPool.icon} className="text-gray-200" />

            <span className="text-sm text-grayscale-200">{selectedPool.name}</span>
          </ListboxOption>
        </div>
      </ListboxOptions>
    </Listbox>
  );
}
