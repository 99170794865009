import { useEffect, useState } from 'react';
import Button from 'src/components/button/Button';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import { useAccount } from 'wagmi';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { useHttp } from 'src/hooks';
import SuccessRedeemedModal from 'src/components/common/modal/SuccessRedeemedModal';
import ArenaRewardCard from './components/ArenaRewardCard';
import whitelist_dev_p1 from '../../whitelist_dev_p1.json';
import { useNavigate } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';

const environment = process.env.REACT_APP_ENVIRONMENT;
const whitelistP1 = environment === 'production' ? whitelist_dev_p1 : whitelist_dev_p1;

interface WhitelistP1 {
  [address: string]:
    | string
    | {
        signature: string;
        keysOfEachType: number[];
      };
}

const whitelistTypedP1: WhitelistP1 = whitelistP1;

const KEY_NAMES = {
  0: 'Silver Key',
  1: 'Gold Key',
  2: 'Platinum Key',
} as const;

interface RewardEvent {
  user: string;
  requestId: string;
  reward: string;
}

function ProfileKey() {
  const [activeTab, setActiveTab] = useState('keys'); // Handle active tab

  const [activeSidebar, setActiveSidebar] = useState('arena');
  const [activeProject, setActiveProject] = useState<any>(null);
  const [selectedRequest, setSelectedRequest] = useState('');
  const { address, isConnected } = useAccount();
  const { sendRequest } = useHttp();
  const navigate = useNavigate();

  const [isSuccessRedeemedModalOpen, setIsSuccessRedeemedModalOpen] = useState(false);
  const [isLoadingKeys, setLoadingKeys] = useState<boolean | null>(null);
  const [isLoadingAvaxKeys, setLoadingAvaxKeys] = useState<boolean | null>(null);

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});

  const [walletData, setWalletData] =
    useState<{ address: string; keys: Record<number, number> } | null>(null); // Store wallet data

  const [formattedEvents, setFormattedEvents] = useState<RewardEvent[]>([]);
  const [redeemedRequestIds, setRedeemedRequestIds] = useState<string[]>([]);
  const [accessToken, setAccessToken] = useState('');
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const [collectionDetail, setCollectionDetail] = useState<any>();
  const [sidebarItems, setSidebarItems] = useState([]);
  const [avaxKeysCountTotal, setAvaxKeysCountTotal] = useState(0);

  // Sidebar data
  // const sidebarItems = [
  //   { name: 'QIIBEE', icon: '/assets/images/qiibee_logo.png' },
  //   { name: 'arena', icon: '/assets/images/arena-logo.png' },
  //   { name: 'AVAX X INSPECT', icon: '/assets/images/avax_logo.png' },
  //   { name: 'ASCENDEX', icon: '/assets/images/ascendex.png' },
  //   { name: 'PANCAKESWAP', icon: '/assets/images/pancake.png' },
  //   { name: 'EXIT FESTIVAL', icon: '/assets/images/exit.png' },
  // ];

  const tabs: { value: string; label: string; icon: Icons }[] = [
    {
      value: 'keys',
      label: 'Keys',
      icon: 'keyS',
    },
    { value: 'rewards', label: 'Rewards', icon: 'giftS' },
  ];

  const getCollectionDetail = () => {
    sendRequest(
      {
        url: `collections`,
        method: 'GET',
      },
      (data: any) => {
        setCollectionDetail(data);

        setActiveProject(data.filter((collection: any) => collection.slug === 'arena')[0]);
      },
    );
  };

  useEffect(() => {
    getCollectionDetail();
  }, []);

  // useEffect(() => {
  //   if (address) {
  //     getWalletKeysData();
  //     getRedeemedRequestsHandler();
  //   }
  // }, [address]);

  const getWalletKeysData = async () => {
    if (address) {
      sendRequest(
        {
          url: `get-wallet-data/${address}`, // Example endpoint
          method: 'GET',
        },
        async (response: any) => {
          setWalletData({ address: response.address, keys: response.keys });
        },
      );
    }
  };

  const getRedeemedRequestsHandler = async () => {
    if (address) {
      sendRequest(
        {
          url: `codes/redeemed/${address}`, // Example endpoint
          method: 'GET',
        },
        async (response: any) => {
          const requestIds = response.map((item: { requestId: string }) => item.requestId);
          setRedeemedRequestIds(requestIds);
        },
      );
    }
  };

  const parentSetRedemedRequest = (requestId: string, voucherNumber: string) => {
    setRedeemedRequestIds(prevIds => [...prevIds, requestId]);

    setRedeemedVouchers(prevState => ({
      ...prevState,
      [requestId]: voucherNumber, // Store voucherNumber with requestId as the key
    }));

    // setLoggedIn(true)
    // setAccessToken(token)
  };

  const copyToClipboard = (text: string, successMessage: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(successMessage);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    const updatedBlockchainKeys: Record<number, number> = {};
    setLoadingKeys(true);
    // Fetch the balance for each key (0 to 4)
    for (let keyIndex = 0; keyIndex < 3; keyIndex++) {
      const balance = await readKeyBalance(address, keyIndex);
      updatedBlockchainKeys[keyIndex] = balance;
    }
    setLoadingKeys(false);
    console.log('profile page updatedBlockchainKeys', updatedBlockchainKeys);

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  const fetchAvaxKeyBalances = async () => {
    if (!address) return;

    setLoadingAvaxKeys(true);
    const balance = await readAvaxKeyBalance(address);
    setAvaxKeysCountTotal(balance);
    setLoadingAvaxKeys(false);
    console.log('Avaxxxx -> balance', balance);
  };
  const readAvaxKeyBalance = async (walletAddress: string) => {
    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(
        `https://avax-mainnet.g.alchemy.com/v2/irtyiHXuwACSy69kigixjG-pJkVpxiL0`,
      );
      const contract = new ethers.Contract(
        '0xdc21aa7e2C52AcD29eA55cecbcFB29b2d03c62F1',
        [
          {
            inputs: [
              {
                internalType: 'string',
                name: 'name',
                type: 'string',
              },
              {
                internalType: 'string',
                name: 'symbol',
                type: 'string',
              },
            ],
            stateMutability: 'nonpayable',
            type: 'constructor',
          },
          {
            inputs: [],
            name: 'ERC721EnumerableForbiddenBatchMint',
            type: 'error',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'sender',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
            ],
            name: 'ERC721IncorrectOwner',
            type: 'error',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'ERC721InsufficientApproval',
            type: 'error',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'approver',
                type: 'address',
              },
            ],
            name: 'ERC721InvalidApprover',
            type: 'error',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
            ],
            name: 'ERC721InvalidOperator',
            type: 'error',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
            ],
            name: 'ERC721InvalidOwner',
            type: 'error',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'receiver',
                type: 'address',
              },
            ],
            name: 'ERC721InvalidReceiver',
            type: 'error',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'sender',
                type: 'address',
              },
            ],
            name: 'ERC721InvalidSender',
            type: 'error',
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'ERC721NonexistentToken',
            type: 'error',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'index',
                type: 'uint256',
              },
            ],
            name: 'ERC721OutOfBoundsIndex',
            type: 'error',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'approved',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Approval',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                indexed: false,
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'ApprovalForAll',
            type: 'event',
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'Transfer',
            type: 'event',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
            ],
            name: 'balanceOf',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'getApproved',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
            ],
            name: 'isApprovedForAll',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'name',
            outputs: [
              {
                internalType: 'string',
                name: '',
                type: 'string',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'ownerOf',
            outputs: [
              {
                internalType: 'address',
                name: '',
                type: 'address',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'bytes',
                name: 'data',
                type: 'bytes',
              },
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'operator',
                type: 'address',
              },
              {
                internalType: 'bool',
                name: 'approved',
                type: 'bool',
              },
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'bytes4',
                name: 'interfaceId',
                type: 'bytes4',
              },
            ],
            name: 'supportsInterface',
            outputs: [
              {
                internalType: 'bool',
                name: '',
                type: 'bool',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [
              {
                internalType: 'string',
                name: '',
                type: 'string',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'index',
                type: 'uint256',
              },
            ],
            name: 'tokenByIndex',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'index',
                type: 'uint256',
              },
            ],
            name: 'tokenOfOwnerByIndex',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'tokenURI',
            outputs: [
              {
                internalType: 'string',
                name: '',
                type: 'string',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: 'from',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'to',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        provider,
      );
      const balance = await contract.balanceOf(walletAddress);

      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  useEffect(() => {
    if (address && isConnected && activeProject && activeProject?.slug === 'arena') {
      fetchBlockchainKeyBalances();
      fetchAvaxKeyBalances();
      // readRewardBalance(address);
    }
  }, [address, isConnected, activeProject]);

  const readKeyBalance = async (walletAddress: string, keyIndex: number) => {
    const phase1Config = activeProject?.stages?.filter((stage: any) => stage.order === 1)[0]
      ?.metadata[environment];

    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      console.log('RPC Profile', activeProject?.network.RPCUrl);
      const provider = new ethers.providers.JsonRpcProvider(activeProject?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_KEY.address,
        phase1Config.LOOTY_KEY.abi,
        provider,
      );
      const balance = await contract.balanceOf(walletAddress, keyIndex);

      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  // const readRewardBalance = async (walletAddress: string) => {
  //   const phase2Config = activeProject?.stages?.filter((stage: any) => stage.order === 2)[0]
  //     .metadata[environment];
  //   try {
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const lootyQiibeeContract = new ethers.Contract(
  //       phase2Config.LOOTY_ARENA_TOKEN.address,
  //       phase2Config.LOOTY_ARENA_TOKEN.abi,
  //       provider,
  //     );
  //
  //     const filter = lootyQiibeeContract.filters.Reward(walletAddress);
  //     const events = await lootyQiibeeContract.queryFilter(filter);
  //
  //     const formattedEvents = events.map(event => ({
  //       user: event?.args?.roller,
  //       requestId: event?.args?.requestId.toString(),
  //       reward: event?.args?.reward.toString(),
  //     }));
  //
  //     setFormattedEvents(formattedEvents);
  //   } catch (error) {
  //     console.error('Error reading rewards:', error);
  //     return 0;
  //   }
  // };

  const redeemHandler = (requestId: string) => {
    setIsSuccessRedeemedModalOpen(true);
    setSelectedRequest(requestId);
  };

  const viewCOdeHandler = (requestId: string) => {
    setSelectedRequest(requestId);
    if (!loggedIn) signInWithMetaMask(requestId);
    else {
      redeemVoucherHandler(requestId, accessToken);
    }
  };

  const [redeemedVouchers, setRedeemedVouchers] = useState<{ [key: string]: string }>({});
  const redeemVoucherHandler = async (selectedRequest: string, token: string) => {
    sendRequest(
      {
        url: `codes/redeem/${selectedRequest}`, // Example endpoint
        method: 'PATCH',
        // payload: { selectedRequest },
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Api-Key': process.env.REACT_APP_API_KEY as string,
        },
      },
      async (response: any) => {
        setRedeemedVouchers(prevState => ({
          ...prevState,
          [selectedRequest]: response.voucherNumber, // Store voucherNumber with requestId as the key
        }));
      },
    );
  };

  const requestNonce = async (address: string) => {
    const response = await fetch('http://localhost:3000/auth/request-nonce', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ walletAddress: address }),
    });
    const data = await response.json();
    return data.nonce;
  };

  const verifySignature = async (address: string, signature: string) => {
    const response = await fetch('http://localhost:3000/auth/verify-signature', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ walletAddress: address, signature }),
    });
    const data = await response.json();
    return data;
  };

  const signInWithMetaMask = async (requestId: string) => {
    if (!window.ethereum) {
      alert('MetaMask not installed!');
      return;
    }

    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = await provider.getSigner();
    const address = await signer.getAddress();
    // setWalletAddress(address);

    // Request the nonce from the backend
    const nonce = await requestNonce(address);

    // Sign the exact nonce message
    const signature = await signer.signMessage(nonce);

    // Verify the signature with the backend
    const { success, token, id } = await verifySignature(address, signature);
    if (success) {
      setLoggedIn(true);
      // setIsViewCode(!isViewCode)
      setAccessToken(token);
      redeemVoucherHandler(requestId, token);
    }
  };

  const handleSidebarItem = (item: any) => {
    console.log('item.slug', item.slug);
    setActiveSidebar(item.slug);
    setActiveProject(item);
  };

  // const renderContent = () => {
  //   if (activeSidebar === 'arena') {
  //     if (activeTab === 'keys') {
  //       const keys = whitelistTypedP1[address?.toLowerCase()]?.keysOfEachType;

  //       // Calculate the total balance of keys
  //       const totalKeyBalance = Object.values(blockchainKeyBalances).reduce(
  //         (sum, value) => sum + value,
  //         0,
  //       );

  //       return isLoadingKeys ? (
  //         <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[32px] font-joystix text-grayscale-700 h-[620px] uppercase col-span-full">
  //           <SyncLoader color="#B73FFF" size={10} />
  //         </div>
  //       ) : keys?.length > 0 && totalKeyBalance !== 0 ? (
  //         keys.map((keyIndex, index) => (
  //           <div key={index} className="p-5 rounded-md bg-grayscale-800">
  //             <img
  //               src={`/assets/images/arenaKeys/${index}.png`} // Dynamically load key image based on keyIndex
  //               alt={`${KEY_NAMES[index as unknown as keyof typeof KEY_NAMES]}`} // Use dynamic key name
  //               className="rounded-md mb-[17px]"
  //             />
  //             <div className="space-y-[17px]">
  //               <h5 className="uppercase text-brand-primary-light">
  //                 {KEY_NAMES[index as unknown as keyof typeof KEY_NAMES]}
  //               </h5>
  //               <div className="w-full border-b-2 border-grayscale-700 my-[17px]" />
  //               <div className="flex justify-between items-center">
  //                 <div>
  //                   <div className="mb-[4px] text-xs text-grayscale-300">Ready to use:</div>
  //                   <div className="font-semibold text-grayscale-100">
  //                     {isLoadingKeys ? (
  //                       <SyncLoader color="#B73FFF" size={5} />
  //                     ) : (
  //                       blockchainKeyBalances[Number(index)] ?? 0
  //                     )}
  //                   </div>
  //                 </div>

  //                 <Button
  //                   onClick={() => navigate(`/arena/openbox/${activeProject?.id}`)}
  //                   size="sm"
  //                   type="primary"
  //                 >
  //                   Open box
  //                 </Button>
  //               </div>
  //             </div>
  //           </div>
  //         ))
  //       ) : (
  //         <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[32px] font-joystix text-grayscale-700 h-[620px] uppercase col-span-full">
  //           No keys found
  //         </div>
  //       );

  //     } else if (activeTab === 'rewards') {
  //       return <ArenaRewardCard activeProject={activeProject} />;

  //       // return formattedEvents.map((event, index) => (
  //       //   <div key={index} className="p-5 rounded-md bg-grayscale-800 min-w-fit max-w-fit">
  //       //     <div className="relative">
  //       //       <img
  //       //         src="/assets/images/credit.png" // You can update this to dynamic image if required
  //       //         alt={`Reward ${event.reward}`}
  //       //         className="w-[271px] h-[271px] rounded-md mb-[17px]"
  //       //       />
  //       //       {/* Example for showing redeemed status, you can customize this based on your logic */}
  //       //       <div className="absolute inset-0 bg-[#00000040] py-3.5 px-3 flex flex-col justify-between">
  //       //         {redeemedRequestIds.includes(event.requestId) && (
  //       //           <>
  //       //             <div className="bg-system-success py-1 px-2.5 text-grayscale-900 text-xs leading-none font-semibold flex items-center gap-1.5 rounded w-fit">
  //       //               <IconSvg icon="checkS" className="text-grayscale-900" />
  //       //               <div>Redeemed</div>
  //       //             </div>

  //       //             {redeemedVouchers[event.requestId] ? (
  //       //               <div className="py-3.5 px-4 bg-grayscale-600 rounded text-grayscale-300 leading-none text-sm w-full justify-between flex items-center">
  //       //                 <div className="max-w-[120px] truncate">
  //       //                   {redeemedVouchers[event.requestId] || 'loading . . .'}
  //       //                 </div>

  //       //                 <IconSvg
  //       //                   icon="copyS"
  //       //                   className="cursor-pointer"
  //       //                   onClick={() => copyToClipboard(event.requestId, 'Request ID copied!')}
  //       //                 />
  //       //               </div>
  //       //             ) : (
  //       //               <img
  //       //                 src="/assets/images/blue-input.png"
  //       //                 className="mx-auto h-10 cursor-pointer"
  //       //                 onClick={() => viewCOdeHandler(event.requestId)}
  //       //               />
  //       //             )}
  //       //           </>
  //       //         )}
  //       //       </div>
  //       //     </div>

  //       //     <div className="space-y-[17px]">
  //       //       <h5 className="uppercase text-brand-primary-light">{event.reward} credits</h5>

  //       //       <div className="w-full border-b-2 border-grayscale-700 my-[17px]" />

  //       //       <div className="flex justify-between items-center">
  //       //         <div>
  //       //           <div className="mb-[4px] text-xs text-grayscale-300">Estimated value:</div>
  //       //           <div className="font-semibold text-grayscale-100">≈ {event.reward}</div>
  //       //         </div>

  //       //         {redeemedRequestIds.includes(event.requestId) ? (
  //       //           <Button size="sm" type="primary">
  //       //             Get VIR
  //       //           </Button>
  //       //         ) : (
  //       //           <Button size="sm" type="primary" onClick={() => redeemHandler(event.requestId)}>
  //       //             Redeem
  //       //           </Button>
  //       //         )}
  //       //       </div>
  //       //     </div>
  //       //   </div>
  //       // ));
  //     }
  //   }
  //   else if(activeSidebar === 'avax') {
  //     if (activeTab === 'keys') {

  //       return isLoadingAvaxKeys ? (
  //         <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[32px] font-joystix text-grayscale-700 h-[620px] uppercase col-span-full">
  //           <SyncLoader color="#B73FFF" size={10} />
  //         </div>
  //       ) : avaxKeysCountTotal !== 0 ? (
  //         <div className="flex justify-center items-center p-5 rounded-md bg-grayscale-800">
  //           {/*<img*/}
  //           {/*  src={`/assets/images/arenaKeys/${index}.png`} // Dynamically load key image based on keyIndex*/}
  //           {/*  alt={`${KEY_NAMES[index as unknown as keyof typeof KEY_NAMES]}`} // Use dynamic key name*/}
  //           {/*  className="rounded-md mb-[17px]"*/}
  //           {/*/>*/}
  //           <div className="space-y-[10px]">
  //             <h5 className="uppercase text-brand-primary-light">
  //               Avax X Inspect Season 2 Keys ({avaxKeysCountTotal})
  //             </h5>

  //             {/*<div className="w-full border-b-2 border-grayscale-700 my-[17px]" />*/}
  //             {/*<div className="flex justify-between items-center">*/}
  //             {/*  <div>*/}
  //             {/*    <div className="mb-[4px] text-xs text-grayscale-300">Ready to use:</div>*/}
  //             {/*    <div className="font-semibold text-grayscale-100">*/}
  //             {/*      */}
  //             {/*    </div>*/}
  //             {/*  </div>*/}

  //             {/*  <Button*/}
  //             {/*    onClick={() => navigate(`/arena/openbox/${activeProject?.id}`)}*/}
  //             {/*    size="sm"*/}
  //             {/*    type="primary"*/}
  //             {/*  >*/}
  //             {/*    Open box*/}
  //             {/*  </Button>*/}
  //             {/*</div>*/}
  //           </div>
  //         </div>
  //       ) : (
  //         <div
  //           className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[32px] font-joystix text-grayscale-700 h-[620px] uppercase col-span-full">
  //           No keys found
  //         </div>
  //       );

  //     } else if (activeTab === 'rewards') {
  //     return (
  //       <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[32px] font-joystix text-grayscale-700 h-[620px] uppercase col-span-full">
  //         No rewards found
  //       </div>
  //     )}
  //   } else {
  //     // Render empty state for other sidebar items
  //     return (
  //       // <div className="flex flex-col col-span-5 justify-center text-center">
  //       //   <h3 className="mb-4 text-white uppercase">Claim Your Rewards</h3>

  //       //   <p className="mb-6 text-grayscale-400">
  //       //     Open box to claim your rewards and unlock exciting surprises. Amazing rewards are
  //       //     waiting for you.
  //       //   </p>

  //       //   <div className="mx-auto w-fit">
  //       //     <Button size="m" type="primary">
  //       //       Open box
  //       //     </Button>
  //       //   </div>
  //       // </div>

  //       <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[32px] font-joystix text-grayscale-700 h-[620px] uppercase col-span-full">
  //         No {activeTab === 'keys' ? 'keys' : 'rewards'} found
  //       </div>
  //     );
  //   }
  // };

  const renderContent = () => {
    if (activeSidebar === 'arena') {
      if (activeTab === 'keys') {
        return (
          <div className="w-full grid gap-[17px] grid-cols-[repeat(auto-fit,minmax(280px,1fr))] justify-between">
            {(() => {
              const ids = whitelistTypedP1[address.toLowerCase()]?.ids;
              const totalKeyBalance = Object.values(blockchainKeyBalances).reduce(
                (sum, value) => sum + value,
                0,
              );

              console.log('idssss', ids);

              if (isLoadingKeys) {
                return (
                  <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 sm:h-[400px] lg:h-[400px] uppercase col-span-full">
                    <SyncLoader color="#B73FFF" size={10} />
                  </div>
                );
              } else if (totalKeyBalance !== 0) {
                return Object.entries(blockchainKeyBalances)
                  .filter(([keyIndex, value]) => value > 0)
                  .map(([keyIndex, balance], index) => (
                    <div key={index} className="p-5 rounded-md bg-grayscale-800">
                      <img
                        src={`/assets/images/arenaKeys/${keyIndex}.png`}
                        alt={`${KEY_NAMES[Number(keyIndex) as keyof typeof KEY_NAMES]}`}
                        className="rounded-md mb-[17px]"
                      />
                      <div className="space-y-[17px]">
                        <h5 className="uppercase text-brand-primary-light">
                          {KEY_NAMES[Number(keyIndex) as keyof typeof KEY_NAMES]}
                        </h5>
                        <div className="w-full border-b-2 border-grayscale-700 my-[17px]" />
                        <div className="flex justify-between items-center">
                          <div>
                            <div className="mb-[4px] text-xs text-grayscale-300">Ready to use:</div>
                            <div className="font-semibold text-grayscale-100">{balance ?? 0}</div>
                          </div>
                          <Button
                            onClick={() => navigate(`/arena/openbox/${activeProject?.id}`)}
                            size="sm"
                            type="primary"
                          >
                            Open box
                          </Button>
                        </div>
                      </div>
                    </div>
                  ));
              } else {
                return (
                  <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
                    No keys found
                  </div>
                );
              }
            })()}
          </div>
        );
      } else {
        return (
          <div className="w-full flex justify-start">
            <ArenaRewardCard activeProject={activeProject} />
          </div>
        );
      }
    } else if (activeSidebar === 'avax') {
      return (
        <div className="w-full">
          {activeTab === 'keys' ? (
            isLoadingAvaxKeys ? (
              <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
                <SyncLoader color="#B73FFF" size={10} />
              </div>
            ) : avaxKeysCountTotal !== 0 ? (
              <div className="relative">
                <img src="/assets/images/keys-bg.png" className="w-full" alt="Background" />
                <div className="flex absolute inset-0 justify-center items-center">
                  <h5 className="p-3 text-center uppercase bg-opacity-70 rounded-md text-brand-primary-light bg-grayscale-800">
                    Avax X Inspect Season 2 Keys ({avaxKeysCountTotal})
                  </h5>
                </div>
              </div>
            ) : (
              <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
                No keys found
              </div>
            )
          ) : (
            <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
              No rewards found
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="w-full grid gap-[17px] 2xl:grid-cols-3 xl:grid-cols-2 lg:grid-cols-1 justify-between">
          <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
            No {activeTab === 'keys' ? 'keys' : 'rewards'} found
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <SuccessRedeemedModal
        isOpen={isSuccessRedeemedModalOpen}
        selectedRequest={selectedRequest}
        onClose={() => setIsSuccessRedeemedModalOpen(false)}
        parentSetRedemedRequest={parentSetRedemedRequest}
      />

      <section className="mb-[36px] lg:mb-[51px]">
        <div className="flex flex-col justify-between lg:items-end lg:flex-row gap-[36px]">
          <div className="flex gap-6 items-center">
            <IconSvg icon="userPlaceholderXl" className="w-[60px] h-[60px] lg:w-fit lg:h-fit" />

            <div>
              <div className="mb-2 text-sm text-grayscale-400">My profile</div>

              <div className="flex gap-[15px] items-center">
                <h3 className="text-brand-primary-light">
                  {address ? `${address.slice(0, 6)}...${address.slice(-4)}` : 'Not connected'}
                </h3>

                {address && (
                  <IconSvg
                    icon="copyM"
                    className="cursor-pointer text-grayscale-400"
                    onClick={() => copyToClipboard(address, 'Address copied to clipboard')}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-6">
            {/* <div>
              <div className="mb-2 text-xs lg:text-sm text-grayscale-400 lg:text-end">
                Value of rewards
              </div>

              <div className="text-lg font-semibold text-grayscale-100 lg:text-end">≈ $370,000</div>
            </div> */}

            {/* <div className="border-l-2 h-[59px] border-grayscale-700"></div> */}

            <div>
              <div className="mb-2 text-xs lg:text-sm text-grayscale-400 lg:text-end">
                Total keys
              </div>

              <div className="text-lg font-semibold text-grayscale-100 lg:text-end">
                {Object.values(blockchainKeyBalances)?.reduce((acc, value) => acc + value, 0)}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex px-5 py-3 mb-2.5 lg:mb-10 rounded-md bg-grayscale-800">
          <div className="flex gap-2">
            {tabs.map(tab => (
              <div
                key={tab.value}
                onClick={() => setActiveTab(tab.value)}
                className={`px-4 py-2 rounded cursor-pointer select-none flex items-center gap-2 ${
                  activeTab === tab.value ? 'bg-grayscale-600' : ''
                }`}
              >
                <IconSvg
                  icon={tab.icon}
                  className={activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'}
                />

                <div
                  className={`text-xs lg:text-sm font-semibold ${
                    activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                  }`}
                >
                  {tab.label}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between gap-2.5 lg:gap-[18px] lg:flex-row flex-col">
          <div className="min-w-[387px] flex lg:flex-col gap-2.5">
            {collectionDetail &&
              collectionDetail?.map((item, idx) => {
                return (
                  (item.slug === 'avax' || item.slug === 'arena') && (
                    <div
                      key={idx}
                      onClick={() => {
                        handleSidebarItem(item);
                      }}
                      className={`py-[15px] px-[15px] lg:px-5 rounded-md bg-grayscale-800 flex gap-5 items-center min-w-fit ${
                        activeSidebar === item.slug ? 'border-2 border-brand-primary' : ''
                      } cursor-pointer`}
                    >
                      <div className="bg-grayscale-700 w-[48px] h-[48px] lg:w-[60px] lg:h-[60px] rounded-[5px] overflow-hidden">
                        <img src={item.logo} alt={item.name} className="w-full h-full" />
                      </div>

                      <h5 className="uppercase text-brand-primary-light max-w-[210px] truncate lg:block hidden">
                        {item.name}
                      </h5>
                    </div>
                  )
                );
              })}
          </div>

          {collectionDetail && renderContent()}
        </div>
      </section>
    </>
  );
}

export default ProfileKey;
