import { useEffect, useRef, useState } from 'react';

import { useNavigate, useLocation, useParams } from 'react-router-dom';
import IconSvg from 'src/components/common/ui/IconSvg';
import MultiversPointsCard, { CardStatus } from './components/MultiversPointsCard';
import {
  multiversMainPrizesData,
  MultiversPointsCardData,
  multiversPointsCardData,
} from 'src/dummydata';
import MultiversClaimBtn from './components/MultiversClaimBtn';
import LeaderBoardCard from './components/LeaderboardCard';
import RewardCard from 'src/components/common/cards/RewardCard';
import { useHttp, useNotification } from 'src/hooks';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { useWebWalletLogin } from '@multiversx/sdk-dapp/hooks';
import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks/account';
import { getSliderSettings } from '../home/Home';
import Slider from 'react-slick';
import { allowedTwitterUsers } from 'src/allowedTwitterUsers';

interface TopUser {
  walletAddress: string;
  totalPoints: number;
  rank: number;
  key?: string;
}

export default function MultiverxDetail() {
  const { collectionSlug } = useParams();
  const navigate = useNavigate();
  const { sendRequest, error: isError } = useHttp();
  const notify = useNotification();
  const [searchQuery, setSearchQuery] = useState('');
  const [user, setUser] = useState<any>(null);
  const { state } = useLocation();
  // const hasHistory = state?.from || document.referrer;
  const [activeTab, setActiveTab] = useState('all');
  const [address, setAddress] = useState('');
  const [currentSlug, setCurrentSlug] = useState('');
  const [isEnterLoading, setIsEnterLoading] = useState(false);
  const [twitterConnected, setIsTwitterConnected] = useState(false);
  const [activeLeaderBoardTab, setActiveLeaderBoardTab] = useState('users');
  const [updatedCards, setUpdatedCards] =
    useState<MultiversPointsCardData[]>(multiversPointsCardData);
  const [topUsers, setTopUsers] = useState<TopUser[]>([]);
  const [accessToken, setAccessToken] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const { isLoggedIn: isLoggedInMultiversX } = useGetLoginInfo();
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 3;
  const cardContainerRef = useRef<HTMLDivElement | null>(null);
  console.log('user', user);
  const isAllowedUser =
    user?.twitterUsername && allowedTwitterUsers.includes(`${user.twitterUsername}`);

  const tabs: { value: string; label: string; count?: number }[] = [
    { value: 'all', label: 'All' },
    {
      value: 'to-do',
      label: 'To Do',
      count: multiversPointsCardData.filter(card => card.status === 'To Do').length,
    },
    {
      value: 'done',
      label: 'Done',
      count: multiversPointsCardData.filter(card => card.status === 'Done').length,
    },
  ];
  const [initiateLogin] = useWebWalletLogin({
    callbackRoute: window.location.pathname,
    nativeAuth: false,
  });

  const leaderBoardTabs: { value: string; label: string }[] = [
    { value: 'users', label: 'Participants' },
    // { value: 'namecoins', label: 'Namecoins' },
  ];

  const tasksStatus = [
    { slug: 'xportal-wallet-creation', isClaimed: false },
    { slug: 'active-wallet', isClaimed: false },
  ];

  const getCompletedChallenges = (address: string, collectionSlug: string) => {
    setIsEnterLoading(true);
    console.log('dddd', accessToken);
    if (accessToken && collectionSlug) {
      sendRequest(
        {
          url: `user/challenges/completed/${collectionSlug}`,
          method: 'GET',
          accessToken,
        },
        (data: any) => {
          updateCardData(data.challengeData);
          setTopUsers(data.topUsers);
          setIsEnterLoading(false);
        },
      );
    }
  };

  const handleXportalWalletCreation = () => {
    if (accessToken) {
      sendRequest(
        {
          url: `user/update-pending-status`,
          accessToken,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          payload: { type: 'xPortalWalletCreationPending' },
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (accessToken && collectionSlug && !isLoggedInMultiversX) {
            initiateLogin();
          }
          getUserInfo();
        },
      );
    }
  };

  const handleBuyEgld = () => {
    console.log('clicked on buy egld');
    if (accessToken) {
      sendRequest(
        {
          url: `user/update-pending-status`,
          accessToken,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          payload: { type: 'buyEgldPending' },
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (accessToken && collectionSlug) {
            getUserInfo();
          }
        },
      );
    }
  };

  const handleStakeEgld = () => {
    console.log('clicked on byuy egls');
    // window.open('https://multiversx.com/staking/', '_blank');
    console.log('handleStakeEgld', accessToken);
    if (accessToken) {
      sendRequest(
        {
          url: `user/update-pending-status`,
          accessToken,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          payload: { type: 'stakeEgldPending' },
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (accessToken && collectionSlug) {
            getUserInfo();
          }
        },
      );
    }
  };

  const handleActiveWallet = () => {
    console.log('clicked on byuy egls');
    window.open(' https://xportal.com/', '_blank');
  };
  const updateCardData = (completedChallenges: any[]) => {
    const updated = multiversPointsCardData.map(card => {
      const challenge = completedChallenges.find(challenge => challenge.slug === card.slug);
      if (challenge) {
        return {
          ...card,
          completed: challenge.completed,
          status: challenge.completed ? 'Done' : 'To Do',
          isClaimed: challenge.completed,
        };
      }
      return card;
    });

    setUpdatedCards(updated);
  };

  useEffect(() => {
    if (isError && isError.includes('Unable to refresh the access token')) {
      const jwtString = localStorage.getItem('jwt');
      const jdata = JSON.parse(jwtString);
      console.log('while x logout', jdata);

      localStorage.setItem(
        'jwt',
        JSON.stringify({
          token: jdata.token,
          isXLoggedIn: false,
          isMLoggedIn: jwtString && jdata.isMLoggedIn === true,
          isMvxLoggedIn: jwtString && jdata.isMvxLoggedIn === true,
        }),
      );
      notify(`Session expired. Please log in again.`, 'error');
      setTimeout(() => {
        window.location.href = '/';
      }, 500);
    }
  }, [isError]);

  useEffect(() => {
    console.log('window.location.href', accessToken);
    console.log('window.location.href', collectionSlug);

    if (accessToken && collectionSlug) {
      getCompletedChallenges(address, collectionSlug);
    }
  }, [window.location.href, accessToken, collectionSlug]);

  const handleConnectDiscord = () => {
    if (address) {
      sendRequest(
        {
          url: `auth/discord/login/${address}`,
          method: 'GET',
        },
        (data: any) => {
          if (data?.url) {
            window.location.href = data.url;
          }
        },
      );
    }
    console.log('Connect Discord button clicked');
  };

  const handleConnectX = () => {
    // setIsEnterLoading(true);
    setCurrentSlug('connect-x');

    // if (address) {
    sendRequest(
      {
        url: `twitter/login`,
        method: 'GET',
      },
      (data: any) => {
        if (data) {
          // window.open(data, '_blank');

          window.location.href = data;
        }
      },
    );
    // }
    console.log('Connect X button clicked');
    // Add your logic here
  };

  const handleShareReward = () => {
    console.log('Share Reward on Twitter button clicked');
    // Add your logic here
  };

  const handleMintKey = () => {
    if (address) {
      sendRequest(
        {
          url: `user/add-mint-points/${address}`,
          method: 'POST',
        },
        (data: any) => {
          console.log('data', data);
        },
      );
    }
  };

  const handleOpenBox = () => {
    if (address) {
      sendRequest(
        {
          url: `user/add-open-box-points/${address}`,
          method: 'POST',
        },
        (data: any) => {
          console.log('data', data);
        },
      );
    }
    console.log('Open Box button clicked');
    // Add your logic here
  };

  const handleFollowMultiversX = () => {
    setIsEnterLoading(true);
    if (address) {
      sendRequest(
        {
          url: `twitter/follow/follow-multiversx-on-x`,
          accessToken,
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (address && collectionSlug) {
            getCompletedChallenges(address, collectionSlug);
          }
        },
      );
    }
    // Add your logic here
  };

  const handleFollowLootyFi = () => {
    setIsEnterLoading(true);
    if (address) {
      sendRequest(
        {
          url: `twitter/follow/follow-looty-fi-on-x`,
          accessToken,
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (address && collectionSlug) {
            getCompletedChallenges(address, collectionSlug);
          }
        },
      );
    }
    console.log('Follow @Looty_fi on X button clicked');
    // Add your logic here
  };
  const handleFollowXPortalAPP = () => {
    if (address) {
      sendRequest(
        {
          url: `twitter/follow/follow-xportalapp-on-x`,
          accessToken,
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (address && collectionSlug) {
            getCompletedChallenges(address, collectionSlug);
          }
        },
      );
    }
    console.log('Follow @Looty_fi on X button clicked');
    // Add your logic here
  };

  const handleFollowXAllianceDao = () => {
    if (address) {
      sendRequest(
        {
          url: `twitter/follow/follow-xalliancedao-on-x`,
          accessToken,
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (address && collectionSlug) {
            getCompletedChallenges(address, collectionSlug);
          }
        },
      );
    }
  };

  const handleTwitterFollow = (slug: string) => {
    if (address) {
      sendRequest(
        {
          url: `twitter/follow/${slug}`,
          accessToken,
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (address && collectionSlug) {
            getCompletedChallenges(address, collectionSlug);
          }
        },
      );
    }
  };

  const handleSubscribePodcast = () => {
    if (address) {
      sendRequest(
        {
          url: `youtube/login`,
          method: 'GET',
          accessToken,
        },
        (data: any) => {
          if (data?.url) {
            // window.open(data.url, '_blank');
            window.location.href = data.url;
          }
        },
      );
    }
    console.log('Subscribe Full Shard Podcast button clicked');
    // Add your logic here
  };

  const handleJoinTelegram = () => {
    console.log("Join MultiversX's Telegram button clicked");

    if (address) {
      window.open('https://t.me/MultiversX', '_blank');

      sendRequest(
        {
          url: `telegram/mark-joined/${address}`,
          method: 'POST',
        },
        (data: any) => {
          console.log('Request sent:', data);
        },
      );
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('scrollToEarn') === '1' && cardContainerRef.current) {
      // Scroll to the container
      cardContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // Remove 'scrollToEarn' from the search parameters
      params.delete('scrollToEarn');
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }

    return () => {
      console.log('...');
    };
  }, []);

  const handleWatchVideo = () => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page') || currentPage;
    window.location.href = `/video/what-is-egld?page=${page}`;
  };

  const handleWatchVideo2 = () => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page') || currentPage;
    window.location.href = `/video/the-only-crypto-wallet-you-will-ever-need?page=${page}`;
  };

  const handleWatchVideo3 = () => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page') || currentPage;
    window.location.href = `/video/what-is-sharding?page=${page}`;
  };

  const handleWatchVideo4 = () => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page') || currentPage;
    window.location.href = `/video/how-ai-is-changing-the-world?page=${page}`;
  };
  const handleWatchVideo5 = () => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page') || currentPage;
    window.location.href = `/video/why-im-betting-big-on-multiversx?page=${page}`;
  };

  const handleEnter = async (slug: string) => {
    setCurrentSlug(slug);
    // if (!address) {
    //   toast.error('Wallet not connected. Please connect your wallet.');
    //   return;
    // }

    const jwtString = localStorage.getItem('jwt');

    if (!jwtString && slug !== 'xportal-wallet-creation' && slug !== 'buy-egld') {
      handleConnectX();
      // await signInHandler(slug);
    } else {
      // setIsEnterLoading(true);
      handleCardClick(slug); // Proceed to the card-specific logic
    }
  };

  const handleCopyLink = () => {
    if (user) {
      const appUrl = `${window.location.origin}/referral?code=${user.referralCode}`; // The URL to be copied to clipboard
      navigator.clipboard
        .writeText(appUrl) // Copy the URL to clipboard
        .then(() => {
          setIsCopied(true); // Set copied status
          setTimeout(() => setIsCopied(false), 2000); // Reset copied status after 2 seconds
          toast.success('Link copied')
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    }
  };
  useEffect(() => {
    // Check if a token is already present on page load
    const jwtString = localStorage.getItem('jwt');
    if (jwtString) {
      console.log('jwtString', jwtString);
      const data = JSON.parse(jwtString);
      const token = data.token;
      console.log('jwtString: token', token);
      setAccessToken(token);
      setIsTwitterConnected(data.isXLoggedIn);
      // sendRequest(
      //   {
      //     url: 'auth/verify-token',
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     payload: JSON.stringify({ token }),
      //   },
      //   (responseData: any) => {
      //     console.log('verify-token', responseData);
      //
      //     if (responseData.success) {
      //       setAccessToken(responseData.token);
      //     } else {
      //       localStorage.removeItem('jwt');
      //     }
      //   },
      // );
    }
  }, []);

  const signInHandler = async (slug: string) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const address = await signer.getAddress();

    // Request the nonce from the backend
    const nonce = await requestNonce(address);

    // Sign the exact nonce message
    if (nonce) {
      const signature = await signer.signMessage(nonce);

      // Verify the signature with the backend
      const response = await verifySignature(address, signature);
      if (response) {
        const { success, token, id } = response;
        // authToken = token;
        console.log('success, token, id', `${success}, ${token}, ${id}`);
        if (success) {
          localStorage.setItem('jwt', JSON.stringify({ token, id }));

          setAccessToken(token);
          handleCardClick(slug);
        } else {
          toast.error('Signature verification failed');
          return;
        }
      }
    }
  };

  const verifyToken = async (authToken: string): Promise<{ success: boolean } | null> => {
    let verificationResult: { success: boolean } | null = null;

    await new Promise<void>(resolve => {
      sendRequest(
        {
          url: `auth/verify-token`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          payload: JSON.stringify({ authToken }),
        },
        (data: any) => {
          console.log('verifyToken response', data);
          verificationResult = data;
          resolve();
        },
      );
    });

    return verificationResult;
  };

  const requestNonce = async (address: string): Promise<string | null> => {
    let nonce: string | null = null;

    await new Promise<void>(resolve => {
      sendRequest(
        {
          url: `auth/request-nonce`,
          method: 'POST',
          payload: JSON.stringify({ walletAddress: address }),
        },
        (data: any) => {
          console.log('requestNonce response', data);
          nonce = data.nonce;
          resolve();
        },
      );
    });

    return nonce;
  };

  const verifySignature = async (address: string, signature: string) => {
    let result: { success: boolean; token: string; id: string } | null = null;

    await new Promise<void>(resolve => {
      sendRequest(
        {
          url: `auth/verify-signature`,
          method: 'POST',
          payload: JSON.stringify({ walletAddress: address, signature }),
        },
        (data: any) => {
          console.log('verifySignature response', data);
          result = data; // Response ko local variable mein assign karna
          resolve(); // Promise ko resolve karna
        },
      );
    });

    return result;
  };

  const handleXportalSwap = () => {
    if (accessToken) {
      sendRequest(
        {
          url: `user/update-pending-status`,
          accessToken,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          payload: { type: 'xPortalSwapPending' },
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (accessToken && collectionSlug && !isLoggedInMultiversX) {
            initiateLogin();
          }
          getUserInfo();
        },
      );
    }
    console.log('handleXportalSwap()..... called');
  };

  const handleCardClick = (slug: string) => {
    switch (slug) {
      case 'follow-multiversx-on-x':
        handleFollowMultiversX();
        break;
      case 'follow-looty-fi-on-x':
        handleFollowLootyFi();
        break;
      case 'watch-content-video':
        handleWatchVideo();
        break;
      case 'watch-content-video2': {
        handleWatchVideo2();
        break;
      }
      case 'watch-content-video3': {
        handleWatchVideo3();
        break;
      }
      case 'watch-content-video4': {
        handleWatchVideo4();
        break;
      }
      case 'watch-content-video5':
        handleWatchVideo5();
        break;
      case 'xportal-swap': {
        handleXportalSwap();
        break;
      }
      case 'xportal-wallet-creation':
        handleXportalWalletCreation();
        setIsEnterLoading(false);
        break;
      case 'buy-egld':
        handleBuyEgld();
        console.log('Handle Buy $EGLD logic here');
        break;

      case 'active-wallet':
        handleActiveWallet();
        console.log('Handle Active Wallet logic here');
        setIsEnterLoading(false);
        break;

      case 'follow-xportalapp-on-x':
        handleFollowXPortalAPP();
        break;

      case 'follow-boogasx-on-x':
        handleTwitterFollow('follow-boogasx-on-x');
        break;

      case 'follow-tadaio-on-x':
        handleTwitterFollow('follow-tadaio-on-x');
        break;

      case 'follow-hatomprotocol-on-x':
        handleTwitterFollow('follow-hatomprotocol-on-x');
        break;

      case 'follow-ainexus-on-x':
        handleTwitterFollow('follow-ainexus-on-x');
        break;

      case 'follow-xlaunchpadapp-on-x':
        handleTwitterFollow('follow-xlaunchpadapp-on-x');
        break;

      case 'follow-cathenaknights-on-x':
        handleTwitterFollow('follow-cathenaknights-on-x');
        break;

      case 'follow-xoxnonetwork-on-x':
        handleTwitterFollow('follow-xoxnonetwork-on-x');
        break;

      case 'follow-woshrooms-on-x':
        handleTwitterFollow('follow-woshrooms-on-x');
        break;

      case 'follow-boberonmvx-on-x':
        handleTwitterFollow('follow-boberonmvx-on-x');
        break;

      case 'follow-xexchangeapp-on-x':
        handleTwitterFollow('follow-xexchangeapp-on-x');
        break;

      case 'follow-valorofund-on-x':
        handleTwitterFollow('follow-valorofund-on-x');
        break;

      case 'follow-memeversx-on-x':
        handleTwitterFollow('follow-memeversx-on-x');
        break;

      case 'follow-ash_swap-on-x':
        handleTwitterFollow('follow-ash_swap-on-x');
        break;

      case 'follow-wecowcow-on-x':
        handleTwitterFollow('follow-wecowcow-on-x');
        break;

      case 'follow-tomcoinmvx-on-x':
        handleTwitterFollow('follow-tomcoinmvx-on-x');
        break;

      case 'follow-kwakdotlol-on-x':
        handleTwitterFollow('follow-kwakdotlol-on-x');
        break;

      case 'follow-xalliancedao-on-x':
        handleFollowXAllianceDao();
        break;

      case 'join-multiversxs-telegram':
        console.log('join-multiversxs-telegram');
        // handleJoinTelegram();
        break;
      case 'like-and-rt-twitter-posts':
        console.log('Handle Like & RT Twitter Posts logic here');
        break;

      case 'subscribe-to-xalliancedao-youtube':
        handleSubscribePodcast();
        break;
      case 'stake-egld':
        handleStakeEgld();
        console.log('Handle Stake $EGLD logic here');
        break;

      default:
        console.log('No action defined for this slug');
        break;
    }
  };

  const getUserInfo = () => {
    console.log('uuserinfo: accessToken', accessToken);
    sendRequest(
      {
        url: `user/user-info`,
        accessToken,
      },
      (data: any) => {
        setUser(data);
        console.log('userdata', user);
        setAddress(data.id);
      },
    );
  };
  useEffect(() => {
    console.log('uuserinfo: accessToken', accessToken);
    accessToken && getUserInfo();
  }, [accessToken]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pageFromUrl = params.get('page');
    if (pageFromUrl) {
      setCurrentPage(Number(pageFromUrl));
    }
  }, []);

  // Function to filter cards based on the active tab
  const filterCards = (cards: MultiversPointsCardData[]) => {
    return cards.filter(card => {
      switch (activeTab) {
        case 'to-do':
          return card.status === 'To Do';
        case 'done':
          return card.status === 'Done';
        case 'all':
        default:
          return true;
      }
    });
  };

  // Calculate filtered cards
  const filteredCards = filterCards(updatedCards);

  // Determine current cards based on window width
  const currentCards =
    window.innerWidth > 1024
      ? filteredCards // Show all cards if width > 1024
      : filteredCards.slice((currentPage - 1) * cardsPerPage, currentPage * cardsPerPage);

  // Handle page change
  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  //   if (cardContainerRef.current) {
  //     cardContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);

    const params = new URLSearchParams(window.location.search);
    params.set('page', page.toString());
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);

    if (cardContainerRef.current) {
      cardContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleTaskTabChange = (tab: string) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredCards.length / cardsPerPage);

  const sliderSettings = getSliderSettings(
    7,
    {
      1200: 2,
      1000: 1,
    },
    false,
    true,
    window.innerWidth < 1024
      ? {
          right: '-9px',
          left: '-9px',
        }
      : {},
  );

  return (
    <>
      {/* {hasHistory && (
        <button className="flex items-center gap-1.5 mb-[15px]" onClick={() => navigate(-1)}>
          <IconSvg icon="arrowLeftM" className="text-grayscale-400" />
          <span className="text-sm text-grayscale-400">Go Back</span>
        </button>
      )} */}

      <section className="mb-[40px]">
        <div className="flex gap-4 justify-between items-center mb-6 lg:gap-5 lg:justify-start">
          <div
            className="lg:w-[109px] lg:h-[109px] w-[48px] h-[48px] rounded-md"
            style={{
              backgroundImage: 'url(/assets/images/multivers-icon.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>

          <div className="space-y-[12px]">
            <div className="flex gap-[13px] items-end">
              <h3 className="uppercase text-brand-primary-light w-fit text-lg lg:text-[32px] max-w-[148px] lg:max-w-fit">
                multiversx PRESEASON
              </h3>

              <div className="text-xs lg:text-sm text-grayscale-200">EGLD</div>
            </div>
            {/* For desktop */}
            <div className="lg:flex hidden gap-[9px] items-center">
              <a href={'https://www.multiversx.com'} target="_blank" rel="noopener noreferrer">
                <IconSvg
                  icon="webM"
                  className="text-grayscale-400 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                />
              </a>

              <a href={'https://x.com/MultiversX'} target="_blank" rel="noopener noreferrer">
                <IconSvg
                  icon="twitterM"
                  className="text-grayscale-400 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                />
              </a>
              {/* {user &&
             <button
              className="text-grayscale-400 flex items-center gap-1"
              onClick={handleCopyLink}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-[12px] h-[12px] lg:w-fit lg:h-fit"
              >
                <path d="M20 12l-8 8-8-8M4 12l8-8 8 8" />
              </svg>
              <span className="text-xs lg:text-sm">{isCopied ? 'Link copied!' : 'Invite'}</span>
            </button>
            }  */}
            </div>
          </div>
          {/* For mobile */}
          <div className="lg:hidden flex gap-[9px] items-center self-end">
            <a href={'https://www.multiversx.com'} target="_blank" rel="noopener noreferrer">
              <IconSvg icon="webM" className="w-6 h-6 text-grayscale-400 lg:w-fit lg:h-fit" />
            </a>

            <a href={'https://x.com/MultiversX'} target="_blank" rel="noopener noreferrer">
              <IconSvg icon="twitterM" className="w-6 h-6 text-grayscale-400 lg:w-fit lg:h-fit" />
            </a>
          </div>
        </div>

        <img
          src="/assets/images/mulitversx-stages.png"
          className="max-w-full lg:max-w-[685px] mb-6"
        />

        <p className="mb-6 text-sm text-grayscale-300 lg:text-base">
          Get ready to explore MultiversX! Season 1 is here to guide you into our ecosystem. Start
          by creating a new wallet, joining our community, and making your first $EGLD purchase.
          These initial steps will open up opportunities for you to earn loot boxes packed with
          $EGLD and exclusive rewards. The more you engage, the higher you&apos;ll rise on our
          leaderboard, unlocking even greater rewards. Begin your adventure today and discover the
          benefits of active participation in MultiversX!
        </p>

        <div className="flex gap-4">
          <div className="lg:w-[336px] w-fit bg-grayscale-800 rounded-md lg:p-4 p-6 space-y-[8px]">
            <div className="flex items-center justify-center w-[44px] h-[44px] bg-grayscale-900 rounded-sm">
              <IconSvg icon="dollarM" className="text-grayscale-400" />
            </div>

            <div className="text-sm text-grayscale-400 lg:text-base">Total Value of Rewards</div>

            <div className="text-grayscale-100 lg:text-[24px] text-lg font-bold">≈ $50,000.00</div>
          </div>
        </div>
      </section>

      <div className="border-t-2 border-grayscale-700 mb-[40px]" ref={cardContainerRef}></div>

      <section className="mb-[40px]">
        <div className="space-y-[10px] mb-[32px]">
          <h3 className="uppercase text-brand-primary-light text-[32px]">
            Earn points, unlock rewards!
          </h3>

          <p className="text-sm text-grayscale-200">
            Complete fun tasks to climb the leaderboard and reap exclusive prizes through loot
            boxes!
          </p>
        </div>

        <div className="flex justify-between items-center px-5 py-3 mb-5 rounded-md lg:mb-10 bg-grayscale-800">
          <div className="flex gap-2">
            {tabs.map(tab => (
              <div
                key={tab.value}
                onClick={() => handleTaskTabChange(tab.value)}
                className={`px-4 py-2 rounded cursor-pointer select-none ${
                  activeTab === tab.value ? 'bg-grayscale-600' : ''
                }`}
              >
                <div
                  className={`text-xs lg:text-sm font-semibold ${
                    activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                  }`}
                >
                  {tab.label}{' '}
                  {tab.count
                    ? `(${
                        updatedCards.filter(card => {
                          switch (tab.value) {
                            case 'to-do':
                              return card.status === 'To Do';
                            case 'done':
                              return card.status === 'Done';
                            case 'all':
                            default:
                              return true;
                          }
                        }).length
                      })`
                    : ''}
                </div>
              </div>
            ))}
          </div>

          <div className="hidden lg:flex items-center space-x-4">
            {isAllowedUser && (
              <button
                onClick={handleCopyLink}
                className="flex items-center gap-2 p-2 lg:px-4 lg:py-[8px] text-sm rounded-md bg-grayscale-700 w-fit text-grayscale-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 2H7.50009H8.5H8.50009V4.00001H9.50003H10.4999H10.5V11H10.4999V11H3.49994V11V10V9.00005H1.5V9V8.00005V3V2H2.5ZM7.50009 4.00001V3H2.5V8.00005H3.49994V5.00001V4.00001H4.49994H7.50009ZM4.49994 9.00005V10H9.50003V5.00001H4.49994V8.00005H4.5V9.00005H4.49994Z"
                    fill="#EEE0F9"
                  />
                </svg>
                <span className="hidden lg:block">Referral Link</span>
              </button>
            )}

            {/* )} */}
            <MultiversClaimBtn
              status="claimable"
              onClick={() => getCompletedChallenges(address, collectionSlug)}
            />
          </div>
        </div>

        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(320px,1fr))]">
          {currentCards.map((card, index) => (
            <MultiversPointsCard
              key={card.slug}
              status={card.status as CardStatus}
              bgImage={card.bgImage}
              phase={card.phase}
              pointsToEarn={card.pointsToEarn}
              title={card.title}
              description={card.description}
              slug={card.slug}
              user={user}
              currentSlug={currentSlug}
              onEnter={handleEnter}
              handleConnectX={handleConnectX}
              onClaim={() => console.log('onClaim')}
              isClaimed={card?.isClaimed}
              twitterConnected={twitterConnected}
              isEnterLoading={isEnterLoading}
              isLoggedInMultiversX={isLoggedInMultiversX}
            />
          ))}

          {currentCards.length === 0 && (
            <div className="px-5 py-[15px] rounded-md items-center bg-grayscale-800 h-[90px] flex">
              <h5 className="text-brand-primary-light">No tasks found</h5>
            </div>
          )}

          {activeTab === 'all' && window.innerWidth > 1024 && (
            <>
              <img src="/assets/images/tasks/blur.png" className="h-full" />
              <img src="/assets/images/tasks/blur.png" className="h-full" />
            </>
          )}
        </div>

        {/* Pagination buttons (only for width <= 1024) */}
        {window.innerWidth <= 1024 && totalPages > 1 && (
          <div className="flex justify-end mt-[18px] gap-1">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`h-[40px] w-[40px] flex items-center justify-center text-xs rounded border ${
                  currentPage === index + 1
                    ? 'bg-grayscale-800 text-white border-transparent'
                    : 'border-grayscale-800 text-grayscale-500'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </section>

      <div className="border-t-2 border-grayscale-700 mb-[40px]"></div>

      <section className="mb-[40px]">
        <div className="space-y-[10px] mb-[32px]">
          <h3 className="text-brand-primary-light text-[32px]">Leaderboard</h3>

          <p className="text-sm text-grayscale-200">
            Complete tasks, earn points and secure your spot at the top for exclusive loot box
            prizes!
          </p>
        </div>

        <div className="flex justify-between items-center px-5 py-3 mb-3 rounded-md bg-grayscale-800">
          <div className="flex gap-2">
            {leaderBoardTabs.map(tab => (
              <div
                key={tab.value}
                onClick={() => setActiveLeaderBoardTab(tab.value)}
                className={`px-4 py-2 rounded cursor-pointer select-none ${
                  activeLeaderBoardTab === tab.value ? 'bg-grayscale-600' : ''
                }`}
              >
                <div
                  className={`text-xs lg:text-sm font-semibold ${
                    activeLeaderBoardTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                  }`}
                >
                  {tab.label}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          {topUsers.filter(user => user.walletAddress.toLowerCase().includes(searchQuery)).length >
            0 && (
            <div className="grid grid-cols-2 mb-3 lg:grid-cols-15">
              <div className="col-span-1 pl-5 text-sm lg:col-span-2 text-grayscale-300">Rank</div>

              <div className="col-span-1 pl-4 text-sm lg:col-span-11 text-grayscale-300 text-end lg:text-start">
                Username
              </div>

              <div className="hidden col-span-full pr-5 text-sm lg:col-span-2 text-grayscale-300 text-end lg:block">
                Points
              </div>
            </div>
          )}

          {topUsers
            .sort((a, b) => (a.key === 'YOU' ? -1 : b.key === 'YOU' ? 1 : a.rank - b.rank))
            .filter(user => user.walletAddress.toLowerCase().includes(searchQuery))
            .map(user => (
              <LeaderBoardCard
                key={user.rank}
                place={user.rank}
                loggedUserRank={user.loggedUserRank}
                wallet={user.walletAddress}
                points={user.totalPoints}
                isCurrentUser={user.key === 'YOU'}
              />
            ))}

          {topUsers.filter(user => user.walletAddress.toLowerCase().includes(searchQuery))
            .length === 0 && (
            <div className="px-5 py-[15px] rounded-md items-center bg-grayscale-800 h-[90px] flex">
              <h5 className="text-brand-primary-light">No participants found</h5>
            </div>
          )}
        </div>
      </section>

      <div className="border-t-2 border-grayscale-700 mb-[40px]"></div>

      <section className="mb-[40px]">
        <div className="space-y-[10px] lg:mb-[32px] mb-[24px]">
          <h3 className="text-brand-primary-light text-[32px]">MAIN PRIZES</h3>

          <p className="text-sm text-grayscale-200">
            Top the season and open loot boxes filled with $EGLD and exclusive prizes!
          </p>
        </div>

        {window.innerWidth > 1024 ? (
          <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
            {multiversMainPrizesData.map((card, index) => (
              <RewardCard
                key={index}
                name={card.name}
                estimatedValue={card.estimatedValue}
                rarity={card.rarity}
                image={card.image}
                size="medium"
                blurName={true}
              />
            ))}
          </div>
        ) : (
          <Slider {...sliderSettings}>
            {multiversMainPrizesData.map((card, index) => (
              <div className="!block mx-auto px-3" key={index}>
                <RewardCard
                  key={index}
                  name={card.name}
                  estimatedValue={card.estimatedValue}
                  rarity={card.rarity}
                  image={card.image}
                  size="medium"
                  blurName={true}
                />
              </div>
            ))}
          </Slider>
        )}
      </section>
    </>
  );
}
