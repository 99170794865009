import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { Collection } from 'src/components/common/cards/types/types';
import Countdown from 'src/components/common/Countdown';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';
import SyncLoader from "react-spinners/SyncLoader";

interface Props {
  arenaTotalMintedKeys: string;
  collection: Collection;
  handleClick: (collection: Collection, buttonType: 'mint' | 'openbox' | 'checkReward') => void;
  index: number;
}

export default function MintTableRow({ collection, handleClick, arenaTotalMintedKeys, index }: Props) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const navigate = useNavigate();
  const getIconName = (networkCurrency: string): Icons => {
    switch (networkCurrency) {
      case 'AVAX':
        return 'avaxM';
      case 'ETH':
        return 'ethM';
      case 'BNB':
        return 'bnbM';
      case 'SOL':
        return 'solM';
      case 'EGLD':
        return 'egld';
      case 'SUI':
        return 'suiM';
      default:
        return 'ethM'; // Default icon if currency is not listed
    }
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen); // Toggle the accordion state
  };

  const iconName = getIconName(collection.network.networkCurrency);

  // const startingSoon = collection?.stages[0]?.isSoon;

  const isBlurred = !collection?.isEarnBlured;

  const isDesktop = window.innerWidth > 1024;
  return (
    <>
      {isDesktop ? (
        <div
          onClick={!collection?.isEarnBlured ? toggleAccordion : undefined}
          className={`grid grid-cols-15 cursor-pointer px-5 py-[17.5px] rounded-md mb-2.5 last-of-type:mb-0 items-center ${
            isAccordionOpen ? 'bg-grayscale-900 border-2 border-grayscale-700' : 'bg-grayscale-800'
          }`}
        >
          <div className="flex col-span-4 gap-x-5 items-center text-sm text-grayscale-300">
            <img
              src={
                isBlurred
                  ? collection.logo
                  : `/assets/images/blur-logo-${index}.png`
              }
              className="w-[60px] h-[60px] bg-cover bg-center"
            />

            <h5 className="text-brand-primary-light max-w-[255px] rounded-[5px] pr-2.5">
              {isBlurred ? (
                collection.name
              ) : (
                <img
                  src={`/assets/images/blur-collection-name-${
                    Math.floor(Math.random() * 2) + 1
                  }.png`}
                  alt="blur collection name"
                />
              )}
            </h5>
          </div>

          <div className="col-span-2">
            {isBlurred ? (
              <>
                <p className="text-base font-semibold 2xl:text-lg text-grayscale-100">
                  ≈ ${Number(collection.rewardsTotalValue).toLocaleString()}
                </p>
              </>
            ) : (
              <img src={'/assets/images/blur-collection-name-sm.png'} alt="blur price" />
            )}
          </div>

          <div className="flex col-span-2 items-center space-x-2">
            {isBlurred ? (
              <>
                <IconSvg icon={iconName} />

                <p className="font-normal text-xss text-grayscale-300">
                  {collection.network.networkName}
                </p>
              </>
            ) : (
              <img src={'/assets/images/blur-price.png'} alt="blur price" className="w-[110px]" />
            )}
          </div>

          <div className="col-span-3 text-lg font-bold text-start text-grayscale-100">
            {collection?.status === 'Live' && isBlurred && '-'}
          </div>

          <div className="col-span-2 text-grayscale-300">
            {(() => {
              if (collection?.stages[0]?.isExpired) {
                // Expired project
                return (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="expired" />

                    <span className="text-xs text-grayscale-100 2xl:text-sm">Expired</span>
                  </div>
                );
              } else if (collection?.stages[0]?.isLive) {
                // Live project
                return (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="live2M" />
                    <span className="text-xs text-grayscale-100 2xl:text-sm">Live</span>
                  </div>
                );
              } else {
                // Soon project
                return (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="comingSoon2M" />
                    <span className="text-xs text-grayscale-100 2xl:text-sm">Soon</span>
                  </div>
                );
              }
            })()}
          </div>

          <div className="flex col-span-2 justify-end items-center">
            {isBlurred ? (
              <button
                className={`flex items-center p-2 rounded-md gap-x-2 justify-center w-[120px] 2xl:w-[153px] text-xs 2xl:text-base ${
                  isAccordionOpen
                    ? 'bg-brand-primary-light text-grayscale-800'
                    : 'bg-grayscale-700 text-grayscale-200'
                }`}
              >
                <span>{isAccordionOpen ? 'Show less' : 'Show all'}</span>

                <IconSvg
                  icon="arrowDownM"
                  className={`transition-transform duration-300 ${
                    isAccordionOpen ? 'rotate-180 text-grayscale-800' : 'text-brand-primary-light'
                  }`}
                />
              </button>
            ) : (
              <img src={'/assets/images/blur-button.png'} alt="blur button" />
            )}
          </div>
        </div>
      ) : (
        <div
          className={`p-2.5 rounded-md mb-2.5 last-of-type:mb-0 items-center cursor-pointer border-2 lg:hidden ${
            isAccordionOpen
              ? 'bg-grayscale-900 border-grayscale-700'
              : 'bg-grayscale-800 border-transparent'
          }`}
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-x-5 justify-between items-center text-grayscale-300">
              <img
                src={
                  !collection?.isEarnBlured
                    ? collection.logo
                    : `/assets/images/blur-logo-${index}.png`
                }
                className="w-[32px] h-[32px] bg-cover bg-center"
              />

              <h5 className="text-brand-primary-light !leading-[22px] rounded-[5px] text-sm">
                {!collection?.isEarnBlured ? (
                  collection.name
                ) : (
                  <img
                    src={'/assets/images/blur-collection-name-sm.png'}
                    alt="blur collection name"
                  />
                )}
              </h5>
            </div>

            <div className="flex col-span-2 justify-end items-center">
              {!collection?.isEarnBlured ? (
                <button
                  onClick={toggleAccordion}
                  className={`flex items-center p-2 text-xs rounded-md gap-x-2 w-[107px] justify-center ${
                    isAccordionOpen
                      ? 'bg-brand-primary-light text-grayscale-800'
                      : 'bg-grayscale-700 text-grayscale-200'
                  }`}
                >
                  <span>{isAccordionOpen ? 'Show less' : 'Show all'}</span>

                  <IconSvg
                    icon="arrowDownS"
                    className={`transition-transform duration-300 ${
                      isAccordionOpen ? 'rotate-180 text-grayscale-800' : 'text-brand-primary-light'
                    }`}
                  />
                </button>
              ) : (
                <div className="h-[25px] w-[115px] bg-grayscale-700"></div>
              )}
            </div>
          </div>

          <div className="border border-grayscale-700 my-2.5 w-full"></div>

          <div className="flex justify-between items-center">
            <div>
              {!collection?.isEarnBlured ? (
                <>
                  <p className="font-semibold text-grayscale-100 mb-1.5">
                    ≈ $
                    {collection?.rewardsTotalValue
                      ? Number(collection?.rewardsTotalValue).toLocaleString()
                      : ''}
                  </p>

                  <p className="text-xss text-grayscale-500">rewards total value</p>
                </>
              ) : (
                <img src={'/assets/images/blur-info.png'} alt="blur rewards total value" />
              )}
            </div>

            <div>
              {!collection?.isEarnBlured ? (
                <>
                  <IconSvg icon={iconName} className=" mb-1.5" />

                  <p className="text-xss text-grayscale-500">{collection?.network?.networkName}</p>
                </>
              ) : (
                <img src={'/assets/images/blur-info-2.png'} alt="blur price" />
              )}
            </div>

            <div>
              {(() => {
                if (collection?.stages[0]?.isExpired) {
                  return (
                    <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                      <IconSvg icon="expired" />
                      <span className="text-xss text-grayscale-100">Expired</span>
                    </div>
                  );
                } else if (collection?.stages[0]?.isLive) {
                  return (
                    <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                      <IconSvg icon="liveS" />
                      <span className="text-xss text-grayscale-100">Live</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                      <IconSvg icon="comingSoonS" />
                      <span className="text-xss text-grayscale-100">Soon</span>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      )}

      {isAccordionOpen && (
        <>
          {collection.stages
            .filter(st => st?.isShow)
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((stage, index) =>
              isDesktop ? (
                <div
                  key={index}
                  className="grid grid-cols-15 px-5 py-[17.5px] rounded-md bg-grayscale-900 mb-2.5 last-of-type:mb-0 items-center border-2 border-grayscale-700"
                >
                  <div className="flex col-span-4 gap-x-5 items-center text-sm text-grayscale-300">
                    <img src={collection.logo} className="w-[60px] h-[60px]" />

                    <h5 className="text-brand-primary-light max-w-[255px] capitalize">
                      {stage.stageName}
                    </h5>
                  </div>

                  <div className="col-span-2">
                    <p className="text-base font-semibold 2xl:text-lg text-grayscale-100">
                      ≈ ${Number(collection.rewardsTotalValue).toLocaleString()}
                    </p>
                  </div>

                  <div className="col-span-2 flex items-center gap-[9px]"></div>

                  <div className="col-span-3 pr-5 max-w-[300px]">
                    {collection.slug !== 'multiversx' && stage?.isLive && (
                      <>
                        {arenaTotalMintedKeys !== '' ? (
                          <>
                            {stage.isLive && (
                              <ProgressBar
                                progress={Math.floor(((115 + Number(arenaTotalMintedKeys)) / 2206) * 100)}
                                size="Full"
                                estimatedValue={(115 + Number(arenaTotalMintedKeys))}
                                totalValue="2206"
                              />
                            )}
                          </>
                        ) : (
                          <SyncLoader color="#B73FFF" size={5} />
                        )}
                      </>
                    )}
                  </div>

                  <div className="col-span-2 text-sm text-grayscale-300">
                    {(() => {
                      if (stage.isExpired) {
                        // Expired project
                        return (
                          <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                            <IconSvg icon="expired" />

                            <span className="text-sm text-grayscale-100">Expired</span>
                          </div>
                        );
                      } else if (stage.isLive) {
                        // Live project
                        return (
                          <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                            <IconSvg icon="live2M" />
                            <span className="text-sm text-grayscale-100">Live</span>
                          </div>
                        );
                      } else {
                        // Soon project
                        return (
                          <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                            <IconSvg icon="comingSoon2M" />
                            <span className="text-sm text-grayscale-100">Soon</span>
                          </div>
                        );
                      }
                    })()}

                    {stage && collection.slug !== 'multiversx' && stage?.isLive && (
                      <div className="mt-3 text-sm font-semibold text-grayscale-200">
                        <Countdown stage={stage} />
                      </div>
                    )}
                  </div>

                  <div className="flex col-span-2 justify-end items-center">
                    {stage?.isLive && (
                      <div className="flex col-span-2 justify-end items-center">
                        <Button
                          onClick={() =>
                            collection.slug === 'multiversx'
                              ? navigate(`/leaderboards/${collection.slug}`)
                              : navigate(`/arena/${collection.id}/${stage?.id}`)
                          }
                          type="primary"
                          size="xs"
                          classNames="!min-w-[74px]"
                        >
                          {collection.slug === 'multiversx' ? 'Earn' : 'Claim'}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  key={index}
                  className={`p-2.5 rounded-md mb-2.5 last-of-type:mb-0 items-center cursor-pointer border-2 lg:hidden ${
                    isAccordionOpen
                      ? 'bg-grayscale-900 border-grayscale-700'
                      : 'bg-grayscale-800 border-transparent'
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex gap-x-5 justify-between items-center text-grayscale-300">
                      <img src={collection.logo} className="w-[32px] h-[32px] bg-cover bg-center" />

                      <h5 className="text-brand-primary-light !leading-[22px] rounded-[5px] text-sm">
                        {stage?.stageName}
                      </h5>
                    </div>

                    {stage?.isLive && (
                      <Button
                        onClick={() =>
                          collection.slug === 'multiversx'
                            ? navigate(`/leaderboards/${collection.slug}`)
                            : navigate(`/arena/${collection.id}/${stage?.id}`)
                        }
                        type="primary"
                        size="xs"
                        classNames="!min-w-[74px]"
                      >
                        {collection.slug === 'multiversx' ? 'Earn' : 'Claim'}
                      </Button>
                    )}
                  </div>

                  <div className="border border-grayscale-700 my-2.5 w-full"></div>

                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-semibold text-grayscale-100 mb-1.5">
                        ≈ $
                        {collection?.rewardsTotalValue
                          ? Number(collection?.rewardsTotalValue).toLocaleString()
                          : ''}
                      </p>

                      <p className="text-xss text-grayscale-500">rewards total value</p>
                    </div>

                    {collection.slug === 'multiversx' && (
                      <div>
                        {(() => {
                          if (collection?.stages[0]?.isExpired) {
                            return (
                              <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                                <IconSvg icon="expired" />
                                <span className="text-xss text-grayscale-100">Expired</span>
                              </div>
                            );
                          } else if (collection?.stages[0]?.isLive) {
                            return (
                              <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                                <IconSvg icon="liveS" />
                                <span className="text-xss text-grayscale-100">Live</span>
                              </div>
                            );
                          } else {
                            return (
                              <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                                <IconSvg icon="comingSoonS" />
                                <span className="text-xss text-grayscale-100">Soon</span>
                              </div>
                            );
                          }
                        })()}
                      </div>
                    )}

                    {collection.slug === 'arena' && (
                      <div className="w-[51%]">
                        <ProgressBar
                          progress={
                            arenaTotalMintedKeys
                              ? Math.floor(((115 + Number(arenaTotalMintedKeys)) / 2206) * 100)
                              : 0
                          }
                          size="Full"
                          totalValue="2206"
                          estimatedValue={arenaTotalMintedKeys ? (115 + Number(arenaTotalMintedKeys)) : 0}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ),
            )}
        </>
      )}
    </>
  );
}
