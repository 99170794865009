import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Referral: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    
    const urlParams = new URLSearchParams(location.search);
    const referralCode = urlParams.get('code');

    if (referralCode) {
      
      localStorage.setItem('referralCode', referralCode);

      
      navigate('/');
    }
  }, [location, navigate]);

  return <div className="text-white text-center">Loading...</div>; 
};

export default Referral;
