import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttp, useNotification } from 'src/hooks';
import { useAccount } from 'wagmi';
import { Collection } from '../../components/common/cards/types/types.tsx';

const YoutubeCallback: React.FC = () => {
  const navigate = useNavigate();
  const { sendRequest, error: errorSubscribing } = useHttp();
  const notify = useNotification();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('state');
  const { address } = useAccount();
  const [showPopup, setShowPopup] = useState(false);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [accessToken, setAccessToken] = useState('');

  const fetchYoutubeUser = () => {
    console.log('fetchYoutubeUser');
    const jwtString = localStorage.getItem('jwt');
    let token = '';
    if (jwtString) {
      const data = JSON.parse(jwtString);
      token = data.token;

      console.log('xx accessToken', token);
    }
    sendRequest(
      {
        url: `youtube/callback?code=${code}&state=${state}`,
        method: 'GET',
        accessToken: token,
      },
      (data: any) => {
        if (data) {
          window.open(`https://www.youtube.com/@xAllianceDAO`, '_blank');
          // setShowPopup(true); // Show the popup
        }

        navigate('/leaderboards/multiversx');
      },
    );
  };
  const handleSubscribe = () => {
    const jwtString = localStorage.getItem('jwt');
    let token = '';
    if (jwtString) {
      const data = JSON.parse(jwtString);
      token = data.token;

      console.log('xx accessToken', token);
    }

    if (token) {
      sendRequest(
        {
          url: `youtube/subscribe`,
          method: 'POST',
          accessToken: token,
        },
        (response: any) => {
          notify('Successfully subscribed to the channel!', 'success');
          setShowPopup(false);
          navigate('/leaderboards/multiversx');
        },
      );
    }
  };

  // useEffect(() => {
  //   fetchYoutubeUser();
  // }, [address]);

  useEffect(() => {
    if (errorSubscribing) {
      notify(`${errorSubscribing}`, 'error');
      navigate('/');
    }
  }, [errorSubscribing]);

  useEffect(() => {
    getCollections();
  }, []);

  useEffect(() => {
    collections.length > 0 && fetchYoutubeUser();
  }, [collections, accessToken]);

  useEffect(() => {
    // Check if a token is already present on page load
    const jwtString = localStorage.getItem('jwt');
    const url = new URL(window.location.href);
    const pathname = url.pathname.slice(1);
    if (jwtString) {
      console.log('jwtString', jwtString);
      const data = JSON.parse(jwtString);
      const token = data.token;
      console.log('jwtString: token', token);
      setAccessToken(token);

      // sendRequest(
      //   {
      //     url: 'auth/verify-token',
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     payload: JSON.stringify({ token }),
      //   },
      //   (responseData: any) => {
      //     if (responseData.success) {
      //       setAccessToken(responseData.token);
      //     } else {
      //       localStorage.removeItem('jwt');
      //     }
      //   },
      // );
    }
  }, [window.location.href]);

  const getCollections = () => {
    sendRequest(
      {
        url: 'collections',
        method: 'GET',
      },
      (data: any) => {
        setCollections(data);
      },
    );
  };

  return (
    <div>
      {/* {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
          <div className="bg-white rounded-lg p-6 w-80 text-center">
            <h2 className="text-lg font-bold text-black mb-4">Subscribe to the Channel</h2>
            <button
              onClick={handleSubscribe}
              className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800"
            >
              Subscribe
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default YoutubeCallback;
