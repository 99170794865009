import { Collection } from 'src/components/common/cards/types/types';
import IconSvg from 'src/components/common/ui/IconSvg';
import {useEffect, useState} from "react";
import {ethers} from "ethers";
import {useAccount} from "wagmi";
import SyncLoader from "react-spinners/SyncLoader";

interface Props {
  collection?: Collection;
}
function ArenaCollectionBanner({ collection }: Props) {

  const environment = process.env.REACT_APP_ENVIRONMENT;
  const { address, isConnected } = useAccount();
  const [arenaTotalMintedKeys, setArenaTotalMintedKeys] = useState('');
  const readMintedKeyEvents = async () => {
    try {
      const arenaCollection = collection;
      const phase1Config = arenaCollection?.stages?.filter(stage => stage.order === 1)[0]?.metadata[environment]; // right now just for arena
      if (!phase1Config) return;

      const provider = new ethers.providers.JsonRpcProvider(arenaCollection?.network.RPCUrl);
      const contract1 = new ethers.Contract(phase1Config.LOOTY_KEY.address, phase1Config.LOOTY_KEY.abi, provider);
      const eventFilter = contract1.filters.TransferBatch(null, "0x0000000000000000000000000000000000000000");
      const fromBlock = 1;
      const toBlock = 'latest';

      const events = await contract1.queryFilter(eventFilter, fromBlock, toBlock);

      console.log('eeeeeee', events)
      let count = 0;
      const mintedKeys = events.reduce((sum, event) => {
        const ev: any = event;
        for (let i =0; i <  ev?.args[4].length; i++) {
            count +=  ev?.args[4][i].toNumber()
        }
        return count;
      }, 0);
      console.log('readMintedKeyEvents-> Total Minted Keys:', mintedKeys);
      setArenaTotalMintedKeys(mintedKeys);
    } catch (error) {
      console.error("Error initializing event listener:", error);
    }
  };
  useEffect(() => {
    if(address && isConnected && collection?.id)
      readMintedKeyEvents();

  }, [address, isConnected, collection])


  return (
    <div
      className="h-[418px] rounded-md w-full px-10 py-[41.5px] pb-[63.5px] bg-grayscale-800 flex flex-col justify-between relative"
      style={{
        backgroundImage: `url(/assets/images/arena-banner.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="flex z-10 justify-end">
        <div className="flex gap-3">
          <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Value of Rewards</div>

            <div className="text-[24px] font-bold text-grayscale-100">≈ $20,000</div>
          </div>

          <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Minted Keys</div>

            <div className="text-[24px] font-bold text-grayscale-100">
              <div className="flex">
                <div>
                  {arenaTotalMintedKeys !== '' ? (
                    115 + Number(arenaTotalMintedKeys).toLocaleString()
                  ) : (
                    <SyncLoader color="#B73FFF" size={5} />
                  )}
                </div>
                <div>/2206</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex z-10 justify-between items-end">
        <div className="flex gap-[19px] items-center">
          {/* <div className="w-[109px] h-[109px] bg-grayscale-900 rounded-md"></div> */}
          <img src="/assets/images/arena-logo.png" className="w-[109px] h-[109px] rounded-md" />

          <div className="space-y-[12px]">
            <h3 className="uppercase text-brand-primary-light">The Arena</h3>

            <div>
              <div className="flex gap-[9px] items-center">
                <a href="https://arena.social/" target="_blank" rel="noopener noreferrer">
                  <IconSvg icon="webM" className="text-[#E6E6E6]" />
                </a>

                <a href="https://x.com/TheArenaApp" target="_blank" rel="noopener noreferrer">
                  <IconSvg icon="twitterM" className="text-[#E6E6E6]" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="py-2 px-[15px] rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
            <IconSvg icon="liveS" className="text-system-success" />

            <span className="text-sm text-grayscale-100">Live</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArenaCollectionBanner;
