import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import React, { useEffect, useRef, useState } from 'react';
import { slideProp } from 'react-stacked-center-carousel/dist/interfaces';
import { ethers } from 'ethers';

import ArenaCollectionBanner from './components/ArenaCollectionBanner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useHttp } from 'src/hooks';
import { Collection } from 'src/components/common/cards/types/types';
import RaffleTimerModal from 'src/components/common/modal/RaffleTimerModal';
import RaffleClaimKeysModal from 'src/components/common/modal/RaffleClaimKeysModal';
import NoKeysToClaimModal from 'src/components/common/modal/NoKeysToClaimModal';
import RewardCardQiibee from 'src/components/common/cards/RewardCardQiibee';
import SuccessMintModal from 'src/components/common/modal/SuccessMintModal';
import SuccessRedeemedModal from 'src/components/common/modal/SuccessRedeemedModal';
import {
  useAccount,
  useSignMessage,
  useSwitchChain,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi';
import { toast } from 'react-toastify';
import Button from 'src/components/button/Button';
import SyncLoader from 'react-spinners/SyncLoader';

import LootyKeyABI from '../../../contracts/LootyKeyABI.json';
import LootyTokenLotteryABI from '../../../contracts/LootyTokenLotteryABI.json';

import PoolSelect from 'src/components/common/PoolSelect.tsx';
import { ConnectWallet } from 'src/components/common/ConnectWallet';
import ClaimNftLotteryModal from 'src/components/common/modal/ClaimNftLotteryModal';
import { useArenaBalanceOf } from 'src/utilities/looty';

const environment = process.env.REACT_APP_ENVIRONMENT;

import whitelist_dev_p1 from '../../../whitelist_dev_p1.json';
import CollectionDetail from 'src/pages/openbox/CollectionDetail';
import RewardArenaCard from 'src/components/common/cards/RewardArenaCard';
import { rewardArenaCardData } from 'src/dummydata';
const whitelistP1 = environment === 'production' ? whitelist_dev_p1 : whitelist_dev_p1;

interface Whitelist {
  [address: string]:
    | string // For cases where the value is just a string
    | {
        signature: string;
        keysOfEachType: number[];
      }; // For cases where the value is an object with these properties
}
const whitelistTypedP1: Whitelist = whitelistP1;

const nftLotteryContractConfig = {
  address: process.env.REACT_APP_LOOTY_TOKEN_LOTTERY_CONTRACT_ADDRESS as `0x${string}`,
  abi: LootyTokenLotteryABI,
};

const KEY_NAMES = {
  0: 'Silver Key',
  1: 'Gold Key',
  2: 'Platinum Key',
} as const;

const rewards = [
  [294, 1963, 8835], // Silver tier
  [588, 3927, 17672], // Gold tier
  [1177, 7854, 35344], // Platinum tier
];

const rewardIcons = [
  'common-reward.svg',
  'epic-reward.svg',
  'legendary-reward.svg',
  'mythical-reward.svg',
  'rare-reward.svg',
  'uncommon-reward.svg',
];

const data = Array.from({ length: 300 }, (_, index) => {
  const rewardGroup = rewards[index % rewards.length]; // Cycle through rewards groups
  const rewardValue = rewardGroup[index % rewardGroup.length]; // Cycle through rewards in the group

  const imageIndex = (index % 6) + 1; // Cycle through images (1-6)
  const iconIndex = Math.floor(Math.random() * rewardIcons.length); // Randomly pick an icon

  return {
    cover: `/assets/images/reward/arena-reward-${imageIndex}.png`, // Dynamically assign image based on index
    icon: `/assets/images/reward/${rewardIcons[iconIndex]}`, // Dynamically assign random icon
    name: `${rewardValue} ARENA`,
    credits: rewardValue,
  };
});

interface RewardEvent {
  user: string;
  requestId: string;
  nftId?: string;
}

function ArenaCollectionDetail() {
  const navigate = useNavigate();
  const { address, isConnected } = useAccount();
  const { sendRequest } = useHttp();
  const { collectionName } = useParams();
  const [selectedPool, setSelectedPool] = useState<{ id: number; name: string; icon: Icons }>({
    id: 1,
    name: 'All pools',
    icon: 'allM',
  });

  const [collectionDetail, setCollectionDetail] = useState<Collection>();
  const [selectedKey, setSelectedKey] = useState<number | null>(null);
  const [tokenClaimedBalance, setTokenClaimedBalance] = useState<any>(null);

  const [userLotteryData, setUserLotteryData] = useState<any>(null);
  const [isClaimingNft, setIsClaimingNft] = useState(false);

  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean | null>(null);
  const [isPauseNftContract, setIsPauseNftContract] = useState<boolean | null>(null);
  const [isLoadingKeys, setLoadingKeys] = useState<boolean | null>(null);

  const [isClaimNftModal, setIsClaimNftModal] = useState(false);
  const [isRerolling, setIsRerolling] = useState(false);

  const [isRerollingNft, setIsRerollingNft] = useState(false);
  const [isSuccessRedeemedModalOpen, setIsSuccessRedeemedModalOpen] = useState(false);

  const [transactionMessage, setTransactionMessage] = useState('');

  const [loadingOpenBoxBtn, setLoadingOpenBoxBtn] = useState(false);

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});

  const stackedCarouselRef = useRef<StackedCarousel>(); // Ref to StackedCarousel
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0); // Keep track of active slide
  const [spinning, setSpinning] = useState<boolean>(false); // Track if the carousel is spinning
  const spinIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedNft, setSelectedNft] =
    useState<{ cover: string; name: string; credits: number } | null>(null);

  const [formattedEvents, setFormattedEvents] = useState<RewardEvent[]>([]);

  const {
    data: hashOpenbox,
    writeContract: openLootboxContractWrite,
    isPending: isOpening,
    error: errorLootbox,
    isError: isError,
  } = useWriteContract();
  const {
    isLoading: isConfirmingOpenBox,
    isSuccess: isConfirmedOpenBox,
    data: receiptOpenBox,
    error: errorLootBoxTxnReceipt,
  } = useWaitForTransactionReceipt({
    hash: hashOpenbox,
  });

  const {
    data: hashClaimNft,
    writeContract: claimNftContractWrite,
    isPending: isOpeningNftLottery,
    isError: isErrorClaimNft,
  } = useWriteContract();

  const {
    isLoading: isConfirmingClaimNft,
    isSuccess: isConfirmedClaimNft,
    data: receiptClaimNft,
  } = useWaitForTransactionReceipt({
    hash: hashClaimNft,
  });

  useEffect(() => {
    console.log('isConfirmedClaimNft', isConfirmedClaimNft);
    console.log('isConfirmingClaimNft', isConfirmingClaimNft);
    console.log('receiptClaimNft', receiptClaimNft);
    console.log('isErrorClaimNft', isErrorClaimNft);
  }, [isConfirmingClaimNft, isConfirmedClaimNft]);

  const {
    data: hashReroll,
    writeContract: RerollContractWrite,
    isPending: isPendingReroll,
    isError: isErrorReroll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingReroll,
    isSuccess: isConfirmedReroll,
    data: receiptReroll,
  } = useWaitForTransactionReceipt({
    hash: hashReroll,
  });

  useEffect(() => {
    console.log('================================');
    console.log('isConfirmingReroll', isConfirmingReroll);
    console.log('isConfirmedReroll', isConfirmedReroll);
    console.log('receiptReroll', receiptReroll);
    console.log('isPendingReroll', isPendingReroll);
    console.log('isErrorReroll', isErrorReroll);
    console.log('================================');
  }, [isConfirmedReroll, isConfirmingReroll]);

  const {
    data: hashSetApprovedForAll,
    writeContract: writeApprovelForAll,
    isPending: isPendingApprovalForAll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingApprovalForAll,
    isSuccess: isConfirmedApprovalForAll,
    data: receiptApprovalForAll,
  } = useWaitForTransactionReceipt({
    hash: hashSetApprovedForAll,
  });

  useEffect(() => {
    console.log('isConfirmedOpenBox', isConfirmedOpenBox);
    console.log('isConfirmingOpenBox', isConfirmingOpenBox);
    console.log('errorLootbox', errorLootbox);
    console.log('errorLootBoxTxnReceipt', errorLootBoxTxnReceipt);

    console.log('receiptOpenBox', receiptOpenBox);
    if (isConfirmedOpenBox && receiptOpenBox) {
      // setLoadingOpenBoxBtn(false); // Ensure loading state is reset
      // stopSpin(0); // Ensure spinning stops
      setTransactionMessage('Openbox Successful.');
      getUserData(); // Update user data after the transaction
      handleOpenBox();
    }
    console.log('isError', isError);
    if (isError || errorLootBoxTxnReceipt) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      stopSpin(0);
      toast.error('Execution reverted for an unknown reason.');
    }

    console.log('isErrorReroll', isErrorReroll);
    if (isErrorReroll) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      setIsRerollingNft(false);
    }

    console.log('isErrorClaimNft', isErrorClaimNft);
    if (isErrorClaimNft) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      setIsClaimingNft(false);
    }
  }, [
    isConfirmingOpenBox,
    isConfirmedOpenBox,
    receiptOpenBox,
    isError,
    isErrorReroll,
    errorLootBoxTxnReceipt,
    isErrorClaimNft,
  ]);

  // useEffect(() => {
  //   if (address) {
  //     readRewardBalance(address);
  //   }
  // }, [address]);

  const getRarity = (reward: number) => {
    if (reward === 20 || reward === 30 || reward === 45) {
      return 'Common';
    } else if (reward === 60 || reward === 80) {
      return 'Uncommon';
    } else if (reward === 100 || reward === 150) {
      return 'Rare';
    }
    return 'Common'; // Default to Common if no match
  };

  const handleCardClick = (index: number) => {
    setSelectedKey(index);
  };

  const getCollectionDetail = async () => {
    await sendRequest(
      {
        url: `collections/${collectionName}`,
        method: 'GET',
      },
      (data: any) => {
        console.log('data', data);
        setCollectionDetail(data);
      },
    );
  };

  // useEffect(() => {
  //   getCollectionDetail();
  // }, [collectionName]);

  const openRedeemedModal = () => {
    setIsClaimNftModal(false);
    setIsSuccessRedeemedModalOpen(true);
  };

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    const updatedBlockchainKeys: Record<number, number> = {};
    setLoadingKeys(true);
    // Fetch the balance for each key (0 to 4)
    for (let keyIndex = 0; keyIndex < 3; keyIndex++) {
      const balance = await readKeyBalance(address, keyIndex);
      updatedBlockchainKeys[keyIndex] = balance;
    }
    setLoadingKeys(false);

    console.log('updatedBlockchainKeys', updatedBlockchainKeys);

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  useEffect(() => {
    if (address && isConnected && collectionDetail) {
      fetchBlockchainKeyBalances();
      readRewardBalance(address);
      getUserData()

      // getUserData();
    }
  }, [collectionDetail, address, isConnected, location.pathname]);

  useEffect(() => {
    if (address && isConnected) {
      getCollectionDetail();
    }
  }, [address, isConnected, collectionName]);

  const readKeyBalance = async (walletAddress: string, keyIndex: number) => {
    const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
      environment
    ];
    console.log('phase1Config', phase1Config);

    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);

      const contract = new ethers.Contract(
        phase1Config.LOOTY_KEY.address,
        phase1Config.LOOTY_KEY.abi,
        provider,
      );
      const balance = await contract.balanceOf(walletAddress, keyIndex);
      console.log(`keyBalance ${Number(balance)} || keyIndex: ${keyIndex}`);
      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  const getUserData = async () => {
    try {
      const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
        environment
      ];
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );
      const userData = await contract.userLotteryData(address);
      const isPause = await contract.paused();
      console.log('userData', userData);
      setIsPauseNftContract(isPause);
      setUserLotteryData(userData);
      setTokenClaimedBalance(userData?.candidateTokenAmount)
    } catch (error) {
      console.error('Error reading balance:', error);
    }
  };

  const readRewardBalance = async (walletAddress: string) => {
    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const lootyNftContract = new ethers.Contract(
        nftLotteryContractConfig.address,
        nftLotteryContractConfig.abi,
        provider,
      );

      const filter = lootyNftContract.filters.Fulfilled(walletAddress);
      const events = await lootyNftContract.queryFilter(filter);

      const formattedEvents = events.map(event => ({
        user: event?.args?.user,
        requestId: event?.args?.requestId.toString(),
        nftId: event?.args?.amount.toString(),
      }));
      console.log('formattedEvents', formattedEvents);

      setFormattedEvents(formattedEvents);
    } catch (error) {
      console.error('Error reading rewards:', error);
      return 0;
    }
  };

  const openLootbox = async (nKeys: number) => {
    const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
      environment
    ];

    if (!isConnected) {
      toast.error('Please connect your wallet before opening the box.');
      return;
    }

    if (selectedKey === null) {
      toast.error('Please select a key to open the box.');
      return;
    }
    console.log('selectedKey', selectedKey);
    console.log('blockchainKeyBalances', blockchainKeyBalances);
    if (blockchainKeyBalances[selectedKey] === 0) {
      toast.error('No keys available to open this box.');
      return;
    }

    if (isPauseNftContract) {
      toast.error('Contract is Paused, contact admin');
      return;
    }

    try {
      console.log(`startLottery.... ${selectedKey}...`);
      startSpin(); // Start the carousel spin

      setLoadingOpenBoxBtn(true);
      // if (isApprovedForAll) {
      // console.log('openlootbox->isApprovedForAll', isApprovedForAll)
      await openLootboxContractWrite({
        address: phase1Config.LOOTY_TOKEN_LOTTERY.address,
        abi: phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'startLottery',
        args: [selectedKey, nKeys],
      });
      // }
      //  else {
      //   console.log('writeApprovelForAll')
      //   await writeApprovelForAll({
      //     address:phase1Config.LOOTY_KEY.address,
      //     abi:phase1Config.LOOTY_KEY.abi,
      //     functionName: 'setApprovalForAll',
      //     args: [process.env.REACT_APP_LOOTY_AVAX_CONTRACT_ADDRESS, true],
      //   });
      // }
    } catch (error) {
      setLoadingOpenBoxBtn(false);
      stopSpin(0);
      console.log('Error opening Lootbox:', error);
      toast.error('Failed to open Lootbox.');
    }
  };

  useEffect(() => {
    if (collectionDetail) {
      const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
        environment
      ];

      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );

      const onClaimed = (user: string, rewardAmount: ethers.BigNumber) => {
        console.log(
          'claimed userLotterydata =>',
          userLotteryData?.candidateTokenAmount?.toString(),
        );

        if (user.toLowerCase() === address?.toLowerCase()) {
          console.log('Claimed event detected =>', rewardAmount.toString());
          getUserData();
          setTokenClaimedBalance(rewardAmount);
          // fetchBlockchainKeyBalances();
          // setIsClaimingNft(false);
          // // setIsRerolling(false);
          // setIsRerollingNft(false);
          // stopSpin(0)
          toast.success('Arena Token Claimed Successfully');
        }
      };

      contract.on('Claimed', onClaimed);

      return () => {
        contract.off('Claimed', onClaimed);
      };
    }
  }, [address, collectionDetail]);

  const claimNft = async () => {
    const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
      environment
    ];
    console.log('claimNft');
    if (!isConnected) {
      toast.error('Please connect your wallet before claiming the NFT.');
      return;
    }

    // if (Number(userLotteryData?.state) === 2) {
    //   setIsClaimNftModal(false);
    // }

    try {
      console.log('phase1Config', phase1Config);
      setIsClaimingNft(true); // Start loader
      await claimNftContractWrite({
        address: phase1Config.LOOTY_TOKEN_LOTTERY.address,
        abi: phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'claimReward',
      });
    } catch (error) {
      console.log('Error Claiming NFT:', error);
      toast.error('Failed to Claim NFT.');
      setIsClaimingNft(false);
    }
  };

  useEffect(() => {
    if (collectionDetail) {
      const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
        environment
      ];
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );

      const onRolled = (
        user: string,
        requestId: ethers.BigNumber,
        nftContract: string,
        nftId: ethers.BigNumber,
      ) => {
        // alert('ReRoll event detected');
        if (user.toLowerCase() === address?.toLowerCase()) {
          getUserData();
          // fetchBlockchainKeyBalances();
          // setIsRerollingNft(false);
        }
      };

      contract.on('Rolled', onRolled);

      return () => {
        contract.off('Rolled', onRolled);
      };
    }
  }, [address, collectionDetail]);

  const rerollNft = async () => {
    const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
      environment
    ];

    if (!isConnected) {
      toast.error('Please connect your wallet before re-rolling the NFT.');
      return;
    }

    try {
      setIsRerolling(true);
      setIsRerollingNft(true);
      startSpin();
      await RerollContractWrite({
        address: phase1Config.LOOTY_TOKEN_LOTTERY.address,
        abi: phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'reroll',
      });
    } catch (error) {
      console.log('Error Re-rolling NFT:', error);
      toast.error('Failed to re-roll NFT.');
      setIsRerollingNft(false);
    }
  };

  const startSpin = () => {
    console.log('startSpin().......');
    if (spinIntervalRef.current) return; // Prevent multiple intervals
    console.log('startSpin() cross');
    setSpinning(true);
    spinIntervalRef.current = setInterval(() => {
      if (stackedCarouselRef.current) {
        stackedCarouselRef.current.goNext(); // Move to next slide
      }
    }, 350); // Spin with 350ms delay
  };

  const stopSpin = (targetCredits: number) => {
    console.log('stopSpin()......', targetCredits);
    console.log('spinning', spinning);
    // if (!spinning) return;
    console.log('spinIntervalRef.current', spinIntervalRef.current);
    if (spinIntervalRef.current) {
      console.log('tututtartara');
      clearInterval(spinIntervalRef.current); // Clear the interval
      spinIntervalRef.current = null;
    }

    console.log('setSpinning(false)');
    setSpinning(false);

    // Find the slide with the matching credits
    const targetSlideIndex = data.findIndex(item => item.credits === targetCredits);
    console.log('targetSlideIndex', targetSlideIndex);

    if (targetSlideIndex !== -1 && stackedCarouselRef.current) {
      console.log('aaayooooo');
      const steps = (targetSlideIndex + data.length - activeSlideIndex) % data.length;
      console.log('stpes', steps);
      stackedCarouselRef.current.swipeTo(steps); // Swipe to the selected NFT slide
    }
  };

  useEffect(() => {
    console.log('collectionDetail', collectionDetail);

    if (collectionDetail) {
      console.log('useEffect => collectionDetail', collectionDetail);
      const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
        environment
      ];

      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );

      const onFulfilled = (user: string, requestId: ethers.BigNumber, amount: ethers.BigNumber) => {
        console.log('onFulfilled................');
        console.log('user......', user);
        console.log('address......', address);
        // alert('Fulfillled event detected');
        if (user.toLowerCase() === address?.toLowerCase()) {
          setTokenClaimedBalance(amount);
          const randomIndex = Math.floor(Math.random() * data.length);
          console.log('randomIndex', randomIndex);
          const selectedItem = data[randomIndex];
          console.log('data[randomIndex]', data[randomIndex]);
          setSelectedNft(selectedItem);
          getUserData();
          fetchBlockchainKeyBalances();
          console.log('selectedItem.credits', selectedItem.credits);
          // stopSpin(selectedItem.credits);
          setTransactionMessage('NFT Claimed!');
          // setLoadingOpenBoxBtn(false);
          // setIsRerolling(false);
          // stopSpin(0)
          // setIsRerollingNft(false);
          // toast.success('Reward Claimed Successfully');
        }
      };
      console.log('onFulfilled calling...........');
      contract.on('Fulfilled', onFulfilled);

      return () => {
        contract.off('Fulfilled', onFulfilled);
      };
    }
  }, [address, collectionDetail]);

  useEffect(() => {
    // console.log('isRerolling123', isRerolling)
    console.log('user state =>', userLotteryData?.state);
    console.log('user reward token =>', userLotteryData?.candidateTokenAmount?.toString());

    if ((loadingOpenBoxBtn && userLotteryData?.state === 0) || userLotteryData?.state === 2) {
      const randomIndex = Math.floor(Math.random() * data.length);
      console.log('randomIndex', randomIndex);
      const selectedItem = data[randomIndex];
      console.log('data[randomIndex]', data[randomIndex]);
      setSelectedNft(selectedItem);

      console.log('selectedItem.credits', selectedItem.credits);
      stopSpin(selectedItem.credits);
      // toast.success('Reward Claimed Successfully');
      setIsRerolling(true);
      setIsClaimingNft(false);
      setLoadingOpenBoxBtn(false);
      getUserData();
      fetchBlockchainKeyBalances();
      
      setIsRerollingNft(false);
      // toast.success('Arena Token Claimed Successfully');
    }
  }, [userLotteryData?.state]);

  const handleOpenBox = () => {
    if (address) {
      sendRequest(
        {
          url: `user/add-open-box-points/${address}`,
          method: 'POST',
        },
        (data: any) => {
          console.log(data);
        },
      );
    }
    console.log('Open Box button clicked');
    // Add your logic here
  };
  const filteredRewardArenaCardData =
    selectedPool?.id === 1
      ? rewardArenaCardData // Show all items if "All pools" is selected
      : rewardArenaCardData.filter(card => card.poolId === selectedPool.id);

  return (
    <>
      <ClaimNftLotteryModal
        openRedeemedModal={openRedeemedModal}
        isOpen={isRerolling || Number(userLotteryData?.state) === 2}
        onClose={() => setIsRerolling(false)}
        rerollsLeft={userLotteryData?.rerollsLeft}
        userState={userLotteryData?.state}
        nftId={tokenClaimedBalance}
        // nftId={userLotteryData?.candidateTokenAmount}
        claimNft={claimNft}
        isClaimingNft={isClaimingNft}
        rerollNft={rerollNft}
        isRerollingNft={isRerollingNft}
        selectedNft={selectedNft}
      />

      <section className="mb-[36px] xl:mb-[40px]">
        {window.innerWidth > 1024 ? (
          <ArenaCollectionBanner collection={collectionDetail} />
        ) : (
          <div className="w-full bg-gray-800 rounded-md">
            <img src={'/assets/images/arena-banner.png'} className="w-full h-auto max-h-[418px]" />
          </div>
        )}
      </section>

      <section className="pb-[24px] mb-[24px] border-b border-grayscale-700 lg:hidden">
        <div className="flex gap-2.5 items-center  mb-4">
          <div className="w-[60px] h-[60px] lg:w-[109px] lg:h-[109px] rounded-md">
            <img
              src={'/assets/images/arena-logo.png'}
              className="object-contain w-full h-full rounded-md"
            />
          </div>

          <div className="space-y-[8px]">
            <h3 className="uppercase text-brand-primary-light 2xl:text-[32px] text-[17px]">
              The Arena PreSeason
            </h3>

            <div>
              <div className="flex gap-[9px] items-center">
                <a href="https://arena.social/" target="_blank" rel="noopener noreferrer">
                  <IconSvg
                    icon="webM"
                    className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                  />
                </a>

                <a href="https://x.com/TheArenaApp" target="_blank" rel="noopener noreferrer">
                  <IconSvg
                    icon="twitterM"
                    className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-3">
          <div className="bg-grayscale-800 bg-opacity-70 py-3 pr-[12px] pl-[18px] rounded-md">
            <div className="mb-2 text-xs text-grayscale-200 text-end">Value of Rewards</div>

            <div className="text-lg text-grayscale-100">≈ $20,000</div>
          </div>

          <div className="bg-grayscale-800 bg-opacity-70 py-3 pr-[12px] pl-[18px] rounded-md">
            <div className="mb-2 text-xs text-grayscale-200 text-end">Minted Keys</div>

            <div className="text-lg text-grayscale-100">
              <div className="flex">
                <div>0</div>
                <div>/2206</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isConnected ? (
        <>
          <section className="mb-[72px]">
            <div className="mb-10">
              <ResponsiveContainer
                carouselRef={stackedCarouselRef}
                render={(parentWidth, carouselRef) => {
                  const currentVisibleSlide = window.innerWidth > 1024 ? 7 : 3;

                  return (
                    <StackedCarousel
                      ref={carouselRef}
                      slideComponent={Card}
                      slideWidth={window.innerWidth > 1024 ? 268 : 197}
                      carouselWidth={parentWidth}
                      data={data}
                      currentVisibleSlide={currentVisibleSlide}
                      maxVisibleSlide={window.innerWidth > 1024 ? 7 : 3}
                      disableSwipe
                      onActiveSlideChange={activeSlide => {
                        setActiveSlideIndex(activeSlide);
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="space-y-[32px] flex flex-col items-center justify-center">
              <div className="flex lg:gap-[18px] gap-[10px] items-center justify-center flex-wrap">
                {Object.entries(blockchainKeyBalances)
                  .filter(([keyIndex, value]) => value > 0)
                  .map(([keyIndex, value], index) => (
                    <div
                      key={index} // Use `index` as the key to ensure uniqueness
                      onClick={() => value > 0 && handleCardClick(Number(keyIndex))}
                      className={`w-[224px] lg:w-[268px] rounded-md bg-grayscale-800 lg:p-[15px] lg:pb-[17px] p-[10px] cursor-pointer flex lg:flex-col gap-[18px] lg:items-start items-center lg:justify-start ${
                        selectedKey === Number(keyIndex) && value > 0
                          ? 'border-2 border-purple-500'
                          : ''
                      }`}
                    >
                      <img
                        src={`/assets/images/arenaKeys/${keyIndex}.png`}
                        className={`${
                          value > 0 ? '' : 'opacity-50 cursor-not-allowed' // Apply opacity only to the image if disabled
                        } lg:w-[238px] lg:h-[238px] w-[46px] h-[46px] rounded`}
                        alt={`Key ${keyIndex}`}
                      />

                      <div className="space-y-[9px]">
                        <h5 className="uppercase text-[#FAFAFA] lg:text-[18px] text-[14px]">
                          {KEY_NAMES[Number(keyIndex) as keyof typeof KEY_NAMES] ??
                            `Key ${keyIndex}`}
                        </h5>

                        <div className="text-xs text-gray-300 space-x-[7px]">
                          <span>Ready to use:</span>
                          <span className="font-semibold pl-[7px]">
                            {isLoadingKeys ? <SyncLoader color="#B73FFF" size={5} /> : value}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="text-sm text-center text-grayscale-200 lg:text-base">
                <p className="mb-4 lg:mb-0">
                  Open box using your keys and get rewards from your favorite project.
                </p>

                <p className="mb-4">
                  Your NFT will be burned once you use your first key. With each key, you get one
                  reward, but some keys allow <br className="hidden lg:block" /> re-roll for a
                  chance at another reward.
                </p>

                <p className="mb-4 lg:font-bold">
                  Silver keys don&apos;t include re-rolls, Gold keys include 1 re-roll and Platinum
                  keys include 2 re-rolls. After each spin, you can either keep your reward or try
                  again until your re-roll limit is reached.
                </p>

                <p>Remember, only one reward can be claimed per key!</p>
              </div>
              {/* {whitelistTypedP1[address?.toLowerCase()]?.keysOfEachType.length > 0 && ( */}
              {Object.values(blockchainKeyBalances).reduce((acc, value) => acc + value, 0) > 0 && (
                <div className="mx-auto w-fit">
                  {loadingOpenBoxBtn ? (
                    <SyncLoader color="#B73FFF" size={10} />
                  ) : (
                    <div className="flex flex-wrap gap-4 justify-center items-center">
                      <Button
                        size="m"
                        type="primary"
                        classNames={`${
                          blockchainKeyBalances[Number(selectedKey)] < 1
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                        }`}
                        disabled={blockchainKeyBalances[Number(selectedKey)] < 1}
                        onClick={() => openLootbox(1)}
                      >
                        Open box
                      </Button>

                      <Button
                        size="m"
                        type="primary"
                        classNames={`${
                          blockchainKeyBalances[Number(selectedKey)] < 3
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                        }`}
                        disabled={blockchainKeyBalances[Number(selectedKey)] < 3}
                        onClick={() => openLootbox(3)}
                      >
                        Open 3 boxes
                      </Button>

                      <Button
                        size="m"
                        type="primary"
                        classNames={`${
                          blockchainKeyBalances[Number(selectedKey)] < 10
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                        }`}
                        disabled={blockchainKeyBalances[Number(selectedKey)] < 10}
                        onClick={() => openLootbox(10)}
                      >
                        Open 10 boxes
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>

          <section>
            <div className="mb-[15px] lg:mb-[36px] flex justify-between items-center flex-wrap gap-[15px]">
              <h3 className="text-brand-primary-light">Rewards</h3>

              <PoolSelect onChange={value => setSelectedPool(value)} />
            </div>

            <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
              {filteredRewardArenaCardData?.map((card, index) => (
                <RewardArenaCard
                  key={index}
                  name={card.name}
                  estimatedValue={card.estimatedValue}
                  rarity={card.rarity}
                  image={card.image}
                  size="medium"
                />
              ))}
            </div>
          </section>
        </>
      ) : (
        <div className={`text-center`}>
          <ConnectWallet
            closeSidebar={() => {
              console.log('sidebar closed mobile');
            }}
          />
        </div>
      )}
    </>
  );
}

export default ArenaCollectionDetail;

const Card = React.memo(function (props: slideProp) {
  const { data, dataIndex } = props;
  const { cover, name, icon } = data[dataIndex];

  return (
    <div className="w-[197px] lg:w-[268px] h-[197px] lg:h-[268px] rounded-md bg-grayscale-800 p-[15px]">
      <div
        className="relative w-full h-full rounded bg-grayscale-700"
        style={{ backgroundImage: `url(${cover})`, backgroundSize: 'cover' }}
      >
        <div className="bg-grayscale-800 rounded py-1 px-2.5 absolute top-2.5 left-2.5 font-semibold text-xs text-gray-200 flex gap-1.5 items-center">
          {/* <IconSvg icon="rareS" className="text-rarity-rare-light" /> */}
          <img src={icon} className="w-4 h-4" />
          <div>{name} </div>
        </div>
      </div>
    </div>
  );
});
