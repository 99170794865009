import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConnectWallet } from 'src/components/common/ConnectWallet';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';

const links: { path: string; icon: Icons; label: string }[] = [
  { path: '/', icon: 'homeM', label: 'Home' },
  { path: '/mint', icon: 'mint2M', label: 'Earn' },
  { path: '', icon: 'ticketM', label: 'Soon' /*'Play'*/ },
  { path: '', icon: 'createM', label: 'Soon' /*'Create'*/ },
  { path: '', icon: 'fileM', label: 'Soon' /*'Apply'*/ },
  { path: '', /*'/multiversx/leaderboard',*/ icon: 'rocketM', label: 'Soon' /*'Leaderboard'*/ },
  { path: '', icon: 'burnM', label: 'Soon' },
];

type ConnectWalletRef = {
  logOutMVX: () => void;
  logOutTwitter: () => void;
};

function AppNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn: isLoggedInMultiversX } = useGetLoginInfo();
  const [isXLogedIn, setIsXLogedIn] = useState(false);

  const connectWalletRef = useRef<ConnectWalletRef>(null);

  useEffect(() => {
    // Check if a token is already present on page load
    const jwtString = localStorage.getItem('jwt');
    const url = new URL(window.location.href);
    const pathname = url.pathname.slice(1);
    console.log('jwtString', jwtString)
    if (jwtString) {
      const data = JSON.parse(jwtString);
      const token = data.token;
      if (data.isXLoggedIn) setIsXLogedIn(true);
      console.log('data.isXLoggedIn', data.isXLoggedIn);
    }
  }, [window.location.href]);

  const handleLogoutMVX = () => {
    connectWalletRef.current?.logOutMVX();
  };

  const handleLogoutTwitter = () => {
    connectWalletRef.current?.logOutTwitter();
  };

  const closeSidebar = () => {
    setIsOpen(false); // Close the mobile sidebar menu
  };
  return (
    <div className="flex items-center justify-between px-6 lg:pr-[61px] lg:pl-[67px] pt-[25px] lg:mb-[55px] mb-[43px] w-full">
      {window.innerWidth > 1024 ? (
        <>
          <div className="relative w-[121px] h-[34px] cursor-pointer" onClick={() => navigate('/')}>
            <img
              src="/assets/icons/app-logo.png"
              alt="Go to home"
              className="absolute inset-0 w-full h-full opacity-0 logo-hover"
            />

            <img
              src="/assets/icons/app-logo-hover.png"
              alt="Go to home"
              className="absolute inset-0 w-full h-full logo-default"
            />
          </div>

          <div className="flex gap-4 items-center">
            <ConnectWallet closeSidebar={() => {console.log('sidebar closed mobile')}} ref={connectWalletRef} />
          </div>
        </>
      ) : (
        <>
          <div onClick={() => navigate('/')}>
            <img src="/assets/icons/app-logo-mobile.png" alt="App logo" className="w-[32px]" />
          </div>

          <div
            className="p-1 rounded cursor-pointer bg-grayscale-800"
            onClick={() => setIsOpen(!isOpen)}
          >
            <IconSvg icon="menuM" className="text-brand-primary-light" />
          </div>
        </>
      )}

      {window.innerWidth < 1024 && (
        <div
          className={`fixed top-[80px] left-0 bottom-0 right-0 z-50 transition-all ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          <div className="space-y-6 w-full p-5 pb-[29px] bg-grayscale-800">
            <div className="flex flex-wrap gap-3 gap-y-6 justify-end items-center">
              <ConnectWallet closeSidebar={closeSidebar} ref={connectWalletRef} />
            </div>

            <div className="border-t border-grayscale-700"></div>

            <div className="flex flex-col gap-y-6 items-end">
              {links.map(({ path, icon, label }, index) => (
                <div key={index}>
                  {path ? (
                    <Link
                      to={path}
                      data-tooltip-id={`tooltip-${index}`}
                      className="flex items-center gap-x-2.5"
                      onClick={() => setIsOpen(false)}
                    >
                      <IconSvg
                        icon={icon}
                        className={`text-grayscale-500 hover:text-brand-primary-light text-base ${
                          (
                            path && path === '/'
                              ? location.pathname === path
                              : path && location.pathname.includes(path)
                          )
                            ? '!text-brand-primary-light'
                            : ''
                        }`}
                      />

                      <div
                        className={`text-grayscale-500 hover:text-brand-primary-light text-base ${
                          (
                            path && path === '/'
                              ? location.pathname === path
                              : path && location.pathname.includes(path)
                          )
                            ? '!text-brand-primary-light'
                            : ''
                        }`}
                      >
                        {label}
                      </div>
                    </Link>
                  ) : (
                    <div className="flex items-center gap-x-2.5">
                      <IconSvg icon={icon} className="text-grayscale-500" />

                      <div className="text-brand-secondary">{label}</div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="border-t border-grayscale-700"></div>

            <div className="flex gap-x-4 justify-end">
              {isLoggedInMultiversX && (
                <button
                  onClick={handleLogoutMVX}
                  className="flex items-center space-x-2 font-bold cursor-pointer text-xs text-grayscale-200 bg-grayscale-700 rounded-[6px] py-2 px-4"
                >
                  <p>Log Out Wallet</p>
                  <img src="/assets/images/logout-wallet.svg" className="w-3 h-3" />
                </button>
              )}

              {isXLogedIn && (
                <button
                  onClick={handleLogoutTwitter}
                  className="flex items-center space-x-2 font-bold cursor-pointer text-xs text-grayscale-200 bg-grayscale-700 rounded-[6px] py-2 px-4"
                >
                  <p>Log Out Twitter</p>
                  <img src="/assets/images/logout-x.svg" className="w-3 h-3" />
                </button>
              )}
            </div>
          </div>

          <div
            className="w-full h-full bg-black bg-opacity-70"
            onClick={() => setIsOpen(false)}
          ></div>
        </div>
      )}
    </div>
  );
}

export default AppNavbar;
