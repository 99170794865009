import { useEffect, useState } from 'react';
import { useHttp } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';

export default function AdminPanel() {
  const { sendRequest } = useHttp();
  const [accessToken, setAccessToken] = useState('');
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const jwtString = localStorage.getItem('jwt');
    if (jwtString) {
      const data = JSON.parse(jwtString);
      const accessToken = data.token;

      setAccessToken(accessToken);

      sendRequest(
        {
          url: `user/user-info`,
          accessToken,
          method: 'GET',
        },
        (data: any) => {
          setUser(data);

          if (!data || !data?.isAdmin) {
            goBack();
          }
        },
      );
    } else {
      goBack();
    }
  }, []);

  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const downloadMvxDataCsv = () => {
    sendRequest(
      {
        url: `admin/mvx-data/multiversx`,
        accessToken,
        responseType: 'blob',
      },
      (response: any) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'mvx-data-multiversx.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
    );
  };

  const downloadUserTasksDataCsv = () => {
    sendRequest(
      {
        url: `admin/user-tasks/multiversx`,
        accessToken,
        responseType: 'blob',
      },
      (response: any) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'user-tasks-multiversx.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
    );
  };

  const downloadUserRegistrationsDataCsv = () => {
    sendRequest(
      {
        url: `admin/user-registrations`,
        accessToken,
        responseType: 'blob',
      },
      (response: any) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'user-registrations.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
    );
  };

  return (
    <>
      {accessToken && user?.isAdmin && (
        <div>
          <h2 className="mb-8 uppercase text-brand-primary-light">
            <span className="text-brand-primary">Admin</span> Panel
          </h2>

          <div className="p-6 rounded-lg border border-gray-700 bg-gray-800/50">
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-200">
                <thead className="text-xs text-gray-300 uppercase bg-gray-700">
                  <tr>
                    <th className="px-4 py-3">Report</th>
                    <th className="px-4 py-3">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="bg-gray-800">
                    <td className="px-4 py-3">MVX Data & Points</td>

                    <td className="px-4 py-3">
                      <Button type="primary" size="xs" onClick={downloadMvxDataCsv}>
                        Download
                      </Button>
                    </td>
                  </tr>

                  <tr className="bg-gray-900">
                    <td className="px-4 py-3">User List With Each Task</td>

                    <td className="px-4 py-3">
                      <Button type="primary" size="xs" onClick={downloadUserTasksDataCsv}>
                        Download
                      </Button>
                    </td>
                  </tr>

                  <tr className="bg-gray-800">
                    <td className="px-4 py-3">New Signups</td>

                    <td className="px-4 py-3">
                      <Button type="primary" size="xs" onClick={downloadUserRegistrationsDataCsv}>
                        Download
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

