import Button from 'src/components/button/Button';
import IconSvg from '../../../../components/common/ui/IconSvg';
import { useNavigate } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';

interface Props {
  bannerImg: string;
  btnText: string;
  slug: string;
  title: string;
  status: string;
  collectionImage: string;
  website: string;
  twitter: string;
  stageId: string;
  id: string;
  arenaTotalMintedKeys: string;
  projectType: string;
  showButtons?: boolean;
}

const HomeBanner = ({
  bannerImg,
  btnText,
  title,
  status,
  collectionImage,
  slug,
  website,
  twitter,
  projectType,
  showButtons,
  stageId,
  id,
  arenaTotalMintedKeys,
}: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className="h-[304px] lg:h-[418px] rounded-md w-full p-4 pb-[34px] lg:px-10 lg:py-[41.5px] lg:pb-[63.5px] bg-grayscale-800 flex flex-col justify-between relative"
      style={{
        backgroundImage: `url(${bannerImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="flex z-10 justify-between">
        <div>
          <div
            className={`py-2 px-[15px] rounded-md mb-3 flex gap-1.5 items-center border w-fit ${
              status === 'Live'
                ? 'bg-system-success-dark border-system-success'
                : 'bg-system-warning-dark border-system-warning'
            }`}
          >
            <IconSvg icon={status === 'Live' ? 'liveS' : 'comingSoonS'} />

            <span className="text-sm text-grayscale-100">{status}</span>
          </div>
        </div>

        <div className="flex gap-3">
          <div className="bg-black bg-opacity-70 py-2 px-3 lg:py-3 lg:px-[18px] rounded-md">
            <div className="mb-2 text-center text-xss lg:text-sm text-grayscale-200 lg:text-end">
              Value of Rewards
            </div>

            <div className="text-lg lg:text-[24px] lg:font-bold text-grayscale-100">
              {slug === 'arena' ? '$20,000' : slug === 'multiversx' ? '≈ $50,000' : '≈ $150,000'}
            </div>
          </div>
          {slug === 'arena' && (
            <div className="bg-black bg-opacity-70 py-2 px-3 lg:py-3 lg:px-[18px] rounded-md">
              <div className="mb-2 text-center text-xss lg:text-sm text-grayscale-200 lg:text-end">
                Minted Keys
              </div>

              <div className="text-lg lg:text-[24px] lg:font-bold text-grayscale-100">
                <div className="flex">
                  <div>
                    {arenaTotalMintedKeys !== '' ? (
                      (115 + Number(arenaTotalMintedKeys)).toLocaleString()
                    ) : (
                      <SyncLoader color="#B73FFF" size={5} />
                    )}
                  </div>
                  <div>/2206</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex z-10 justify-between items-end">
        <div className="flex gap-[8px] lg:gap-[19px] lg:items-center lg:flex-row flex-col">
          <div className="w-[60px] h-[60px] lg:w-[109px] lg:h-[109px] rounded-md">
            <img src={collectionImage} className="object-contain w-full h-full rounded-md" />
          </div>

          <div className="space-y-[12px]">
            <div className="flex gap-[13px] items-center">
              <h3 className="uppercase text-brand-primary-light 2xl:text-[32px] text-[18px]">
                {title}
              </h3>

              <div className={`text-sm uppercase text-grayscale-200`}>{projectType}</div>
            </div>

            <div>
              <div className="flex gap-[9px] items-center">
                {slug === 'multiversx' && (
                  <div className="space-y-[5px]">
                    <button
                      onClick={() => navigate(`/leaderboards/${slug}`)}
                      className="flex items-center gap-2 p-2 lg:px-4 lg:py-[8px] text-sm rounded-md bg-grayscale-700 w-fit text-grayscale-200"
                    >
                      <IconSvg icon="leaderboardS" className="text-grayscale-200" />

                      <span className="hidden lg:block">Leaderboard</span>
                    </button>
                  </div>
                )}

                {website && (
                  <a href={website} target="_blank" rel="noopener noreferrer">
                    <IconSvg
                      icon="webM"
                      className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                    />
                  </a>
                )}

                {twitter && (
                  <a href={twitter} target="_blank" rel="noopener noreferrer">
                    <IconSvg
                      icon="twitterM"
                      className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                    />
                  </a>
                )}
              </div>

              {slug === 'multiversx' && (
                <a
                  className="underline cursor-pointer text-xss text-grayscale-200"
                  onClick={() => navigate(`/leaderboards/${slug}`)}
                >
                  Get more points
                </a>
              )}
            </div>
          </div>
        </div>
        {status === 'Live' && (
          <Button
            type="primary"
            size="xs"
            classNames="lg:min-w-[84px] min-w-[40px]"
            onClick={() => {
              if (slug === 'arena' && stageId) {
                navigate(`/arena/${id}/${stageId}`);
              } else if (slug === 'multiversx') {
                navigate(`/leaderboards/${slug}`);
              }
            }}
          >
            {btnText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default HomeBanner;
