export const allowedTwitterUsers = [
  'Kyrzel1',
  'SpaceCleaner1',
  'Puneeth_sgr',
  'CryptoDefiM99',
  'BossPenguin7',
  'gunxsol',
  'Habibiofcrypto',
  'daniarchibong',
  'Lisavax2',
  'kamoulox',
  'Ryan_lpe',
  'nups_2',
  '0xFrisk',
  'Crypt0_DeFl',
  'Kyrzel',
  'Sierus1',
  'phfoong',
  'JUSTMHARVEL1',
  'abro9k',
  'mikenike_eth',
  'rawrXBT',
  'aideka_eth',
  'Omni_021',
  'shinigamiXBT',
  '_Eliasoul_',
  'AyazSadiq573048',
  'AaqibSheikh11',
];
