import { useEffect, useState } from 'react';
import { ethers, utils } from 'ethers';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAccount, useSignMessage, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';

import { Stage } from 'src/components/common/cards/types/types';
import NumberInputWithMultiplier from 'src/components/common/input/NumberInputWithMultiplier';
import IconSvg from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';

import Button from 'src/components/button/Button';
import Countdown from 'src/components/common/Countdown';
import { checkIsHashUsed, checkIsHashUsedPhase2 } from 'src/utilities/looty';

interface Props {
  setShowClaimMintSuccessModal: any;
  setShowMintSuccessModal: any;
  stages?: Stage[];
  networkCurrency?: string;
}

const environment = process.env.REACT_APP_ENVIRONMENT;

import whitelist_dev from '../../whitelist_dev.json';
import whitelist_dev_p1 from '../../whitelist_dev_p1.json';
import whitelist_prod from '../../whitelist_prod.json';
import SyncLoader from 'react-spinners/SyncLoader';
const whitelist = environment === 'production' ? whitelist_prod : whitelist_dev;
const whitelistP1 = environment === 'production' ? whitelist_prod : whitelist_dev_p1;

interface Whitelist {
  [address: string]:
    | string
    | {
        signature: string;
        baseTokenAmount: number;
        tokenAmount: string[];
        hash?: string; // Make `hash` optional
      };
}

interface WhitelistP1Entry {
  signature: string;
  keysOfEachType: number[];
}

interface WhitelistP1 {
  [address: string]: WhitelistP1Entry | string; // Allow either an object or a string
}

const whitelistTyped: Whitelist = whitelist;
const whitelistTypedP1: WhitelistP1 = whitelistP1;

const MintStages = ({
  stages,
  setShowMintSuccessModal,
  networkCurrency,
  setShowClaimMintSuccessModal,
}: Props) => {
  console.log('MintStages', stages);

  const phase1Config = stages?.filter(stage => stage.order === 1)[0].metadata[environment];
  // const phase2Config = stages?.filter(stage => stage.order === 2)[0].metadata[environment];
  console.log('MintStages => phase1Config', phase1Config);
  // console.log('MintStages => phase2Config', phase2Config);

  const { signMessageAsync } = useSignMessage();
  const navigate = useNavigate();

  const [selectedStage, setSelectedStage] = useState<any>();
  const [phase1Hash, setPhase1Hash] = useState<boolean>(false);
  const [phase2Hash, setPhase2Hash] = useState<boolean>(false);
  const { address, isConnected, chain } = useAccount();
  const [transactionMessage, setTransactionMessage] = useState('');
  const [isMintingInProgress, setIsMintingInProgress] = useState(false);

  const [isPhase1Loading, setIsPhase1Loading] = useState(false);
  const [isPhase2Loading, setIsPhase2Loading] = useState(false);

  // =================================================================
  const { data: hash, error, isPending, writeContractAsync } = useWriteContract();
  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    data: receipt,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (isConfirming) {
      setTransactionMessage('Transaction in progress...');
    }

    if (isConfirmed && receipt) {
      console.log('MintStages => handleMintKeysPhase2() done....');
      setTransactionMessage('Congratulations! Mint Successful.');
      setIsPhase2Loading(false);

      setShowMintSuccessModal(true);
    }
  }, [isConfirming, isConfirmed, receipt]);

  // =================================================================

  const {
    data: mintHashArena,
    error: errorMintArena,
    isPending: isPendingMintArena,
    writeContractAsync: writeContractMintArena,
  } = useWriteContract();
  const {
    isLoading: isConfirmingMintArena,
    isSuccess: isConfirmedMintArena,
    data: receiptMintArena,
  } = useWaitForTransactionReceipt({
    hash: mintHashArena,
  });

  useEffect(() => {
    if (isConfirmingMintArena) {
      setTransactionMessage('Transaction in progress...');
    }

    if (isConfirmedMintArena && receiptMintArena) {
      console.log('MintStages => handleMintKeysPhase1() done...');
      // Parse the tokenId from logs[0].topics[3]
      // const tokenIdHex = receipt.logs[0].topics[3];
      // const tokenId = ethers.BigNumber.from(tokenIdHex).toString();
      // setTokenId(tokenId); // Set the tokenId state with the parsed value
      // setIsMintingInProgress(false); // Hide progress modal
      setTransactionMessage('Congratulations! Mint Successful.');
      // setIsMintSuccessModalOpen(true); // Show success modal
      // toast.success("Congratulations! Mint Successful.", { autoClose: 6000 });
      setIsPhase1Loading(false);
      setPhase1Hash(true);
      setShowClaimMintSuccessModal(true);
    }
  }, [isConfirmingMintArena, isConfirmedMintArena, receiptMintArena]);

  // =================================================================

  useEffect(() => {
    if (isConnected && address) {
      readPhase1Hash();
      readPhase2Hash();
    }
  }, [isConnected, address, stages, phase1Hash, phase2Hash]);

  type IconName =
    | 'avaxM'
    | 'avaxS'
    | 'ethM'
    | 'ethS'
    | 'bnbM'
    | 'bnbS'
    | 'solM'
    | 'egld'
    | 'solS'
    | 'suiM'
    | 'suiS';

  const getIconName = (networkCurrency: string, size: 'M' | 'S'): IconName => {
    switch (networkCurrency) {
      case 'AVAX':
        return `avax${size}`;
      case 'ETH':
        return `eth${size}`;
      case 'BNB':
        return `bnb${size}`;
      case 'EGLD':
        return `egld`;
      case 'SOL':
        return `sol${size}`;
      case 'SUI':
        return `sui${size}`;
      default:
        return `eth${size}`;
    }
  };

  const iconNameS = getIconName(networkCurrency || '', 'S');
  const iconNameM = getIconName(networkCurrency || '', 'M');

  const handleStageSelect = (stage: any) => {
    setSelectedStage(stage);
  };
  const readPhase1Hash = async (): Promise<void> => {
    if (!address || !whitelistTypedP1[address]) return;

    const keysOfEachType = whitelistTypedP1[address?.toLowerCase()]?.keysOfEachType || [];
    const ids = whitelistTypedP1[address.toLowerCase()]?.ids || [];
    const keyNonce = whitelistTypedP1[address.toLowerCase()]?.keyNonce;
    const messageHash = ethers.utils.solidityKeccak256(
      ["address", "address", "uint256", "uint256", "uint256[]", "uint256[]"],
      [
        address,
        phase1Config.LOOTY_KEY.address,
        keyNonce,
        ids.length,
        ids,
        keysOfEachType,
      ],
    );
    // const messageHash = ethers.utils.solidityKeccak256(
    //   ['address', 'address', 'uint256', 'uint256[]'],
    //   [
    //     address,
    //     phase1Config.LOOTY_KEY_MINTER.address,
    //     // process.env.REACT_APP_LOOTY_KEY_CONTRACT_ADDRESS || '',
    //     keysOfEachType.length,
    //     keysOfEachType,
    //   ],
    // );

    console.log('checking => phase1HashUsed.......');
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const isHashUsed = await checkIsHashUsed(
      provider,
      phase1Config.LOOTY_KEY_MINTER.address || '',
      messageHash,
    );

    console.log('phase1HashUsed', isHashUsed);
    setPhase1Hash(isHashUsed);
  };

  const readPhase2Hash = async (): Promise<void> => {
    if (!address || !whitelistTyped[address] || whitelistTyped[address] === undefined) return;
    console.log('checking => phase2HashUsed.......');

    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    // const isHashUsed = await checkIsHashUsedPhase2(
    //   provider,
    //   phase2Config.LOOTY_FLAT_DISBURSEMENT.address || '',
    //   address,
    //   whitelistTyped[address.toLowerCase()] as {
    //     signature: string;
    //     baseTokenAmount: number;
    //     tokenAmount: string[];
    //   },
    //   phase2Config.LOOTY_ARENA_TOKEN.address || '',
    // );

    const isHashUsed = false;

    console.log('phase2HashUsed', isHashUsed);

    setPhase2Hash(isHashUsed);
  };

  const handleMintKeysPhase1 = async () => {
    console.log('handleMintKeysPhase1 start....');
    if (!isConnected) {
      toast.error('Please connect your wallet before Keys minting.');
      return;
    }

    // if (!whitelistTyped[address]) {
    //   toast.error('User is not whitelisted.');
    //   return;
    // }

    try {
      setIsPhase1Loading(true);

      const keysOfEachType = whitelistTypedP1[address?.toLowerCase()]?.keysOfEachType || [];
      const ids = whitelistTypedP1[address.toLowerCase()]?.ids || [];
      const keyNonce = whitelistTypedP1[address.toLowerCase()]?.keyNonce;
      const messageHash = ethers.utils.solidityKeccak256(
        ["address", "address", "uint256", "uint256", "uint256[]", "uint256[]"],
        [
          address,
          phase1Config.LOOTY_KEY.address,
          keyNonce,
          ids.length,
          ids,
          keysOfEachType,
        ],
      );

      // const messageHash = ethers.utils.solidityKeccak256(
      //   ['address', 'address', 'uint256', 'uint256[]'],
      //   [address, phase1Config.LOOTY_KEY.address, keysOfEachType.length, keysOfEachType],
      // );

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_KEY_MINTER.address,
        phase1Config.LOOTY_KEY_MINTER.abi,
        provider,
      );
      const isUsedHash = await contract.usedHashes(messageHash);
      console.log('phase1Hash check', isUsedHash);

      if (isUsedHash) {
        toast.error('Hash is already used');
        setIsPhase1Loading(false);
        return;
      }

      console.log('writeContractMintArena....');
      await writeContractMintArena({
        address: phase1Config.LOOTY_KEY_MINTER.address as `0x${string}`,
        abi: phase1Config.LOOTY_KEY_MINTER.abi,
        functionName: 'mint',
        args: [whitelistTypedP1[address?.toLowerCase()].signature, phase1Config.LOOTY_KEY.address, keysOfEachType],
      });
      console.log('handleMintKeysPhase1 end....');
    } catch (error: any) {
      setIsPhase1Loading(false);
      console.error('Mint NFT error:', error);
    }
  };

  // const handleMintKeysPhase2 = async () => {
  //   console.log('handleMintKeysPhase2 start....');
  //
  //   if (!isConnected) {
  //     toast.error('Please connect your wallet before Keys minting.');
  //     return;
  //   }
  //
  //   // if (!whitelistTyped[address]) {
  //   //   toast.error('User is not whitelisted.');
  //   //   return;
  //   // }
  //   // if (chain?.id !== targetChainId) {
  //   //   await switchChain?.({ chainId: targetChainId });
  //   // }
  //   // if (balanceOf && Number(balanceOf) >= 1) {
  //   //   toast.info("You have already minted.");
  //   //   return;
  //   // }
  //
  //   // if (
  //   //   etherBalance?.data?.value &&
  //   //   parseFloat(formatEther(etherBalance.data.value)) < Number(nftPrice)
  //   // ) {
  //   //   toast.error("Insufficient balance to Mint NFT");
  //   //   return;
  //   // }
  //
  //   try {
  //     setIsPhase2Loading(true);
  //     console.log('check messagehash value', [
  //       address,
  //       whitelistTyped[address.toLowerCase()].baseTokenAmount,
  //       1,
  //       [phase2Config.LOOTY_ARENA_TOKEN.address],
  //       whitelistTyped[address.toLowerCase()].tokenAmount,
  //     ]);
  //     const messageHash = ethers.utils.solidityKeccak256(
  //       ['address', 'uint256', 'uint256', 'address[]', 'uint256[]'],
  //       [
  //         address,
  //         whitelistTyped[address.toLowerCase()].baseTokenAmount,
  //         1,
  //         [phase2Config.LOOTY_ARENA_TOKEN.address],
  //         whitelistTyped[address.toLowerCase()].tokenAmount,
  //       ],
  //     );
  //
  //     console.log('phase2Hash check');
  //     console.log('messageHash', messageHash);
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const contract = new ethers.Contract(
  //       phase2Config.LOOTY_FLAT_DISBURSEMENT.address,
  //       phase2Config.LOOTY_FLAT_DISBURSEMENT.abi,
  //       provider,
  //     );
  //
  //     console.log('phase2Config', phase2Config);
  //
  //     const isUsedHash = await contract.usedHashes(messageHash);
  //
  //     console.log('phase2Hash return', isUsedHash);
  //
  //     if (isUsedHash) {
  //       toast.error('Hash is already used');
  //       setIsPhase2Loading(false);
  //       return;
  //     }
  //     console.log('writeContractMintArenaForPhase2....');
  //
  //     await writeContractAsync({
  //       address: phase2Config.LOOTY_FLAT_DISBURSEMENT.address,
  //       abi: phase2Config.LOOTY_FLAT_DISBURSEMENT.abi,
  //       functionName: 'mint',
  //       args: [
  //         whitelistTyped[address.toLowerCase()].signature,
  //         whitelistTyped[address.toLowerCase()].baseTokenAmount,
  //         [phase2Config.LOOTY_ARENA_TOKEN.address],
  //         whitelistTyped[address.toLowerCase()].tokenAmount,
  //       ],
  //       // value: BigInt(priceInEther.toString()),
  //     });
  //   } catch (error: any) {
  //     console.error('Mint NFT error:', error);
  //     setIsPhase2Loading(false);
  //
  //     // setMintError(error?.message || "Minting failed.");
  //     setTransactionMessage('Minting failed. Please try again.');
  //     toast.error(error?.message || 'Minting failed.');
  //     setIsMintingInProgress(false);
  //   }
  // };

  return (
    <>
      <div className="w-[46.46%] bg-grayscale-800 p-[30px] pb-[40px] rounded-md h-fit">
        <div className="font-semibold text-[24px] text-brand-primary-light mb-[25px]">
          Earn stages
        </div>

        <div className="space-y-3.5 mb-[25px]">
          {stages?.map((stage, index) => (
            <div
              key={stage.id}
              onClick={() => handleStageSelect(stage)} // Ensure stage.id is passed
              className={`flex items-center justify-between px-5 py-[30px] border-2 rounded-md cursor-pointer ${
                // stage?.isLive
                selectedStage?.stageName === stage?.stageName
                  ? 'border-system-success'
                  : stage?.isSoon
                  ? 'border-grayscale-700'
                  : 'border-grayscale-700'
              }`}
            >
              <div className="w-[196px] space-y-1.5">
                <div className="text-lg font-semibold text-brand-primary-light max-w-[196px] truncate">
                  {stage.stageName}
                </div>
                {stage.order === 1 &&
                  (phase1Hash ? (
                    <div className="text-xss text-grayscale-300">Keys Already Claimed</div>
                  ) : whitelistTypedP1[address?.toLowerCase()] ? (
                    <div className="text-xss text-grayscale-300">You&apos;re eligible</div>
                  ) : (
                    <div className="text-xss text-grayscale-300">You&apos;re not eligible</div>
                  ))}

                {stage.order === 2 &&
                  (phase2Hash ? (
                    <div className="text-xss text-grayscale-300">Reward Already Claimed</div>
                  ) : whitelistTyped[address.toLowerCase()] ? (
                    <div className="text-xss text-grayscale-300">You&apos;re eligible</div>
                  ) : (
                    <div className="text-xss text-grayscale-300">You&apos;re not eligible</div>
                  ))}
              </div>

              <div className="w-[104px] space-y-[7px] text-center flex flex-col items-center">
                <div className="text-sm text-grayscale-300">Mint price</div>
                <div className="flex gap-[9px] items-center">
                  <IconSvg icon={iconNameS} />
                  <div className="font-semibold text-gray-100">
                    {parseFloat(stage?.mintPrice).toFixed(3)}
                  </div>
                </div>
              </div>

              <div className="w-[66px] space-y-[7px] text-center">
                <div className="text-sm text-grayscale-300">Limit</div>
                <div className="font-semibold text-gray-100">{stage?.limit}</div>
              </div>

              <div className="w-[168px] flex justify-end flex-col items-end gap-y-2.5">
                <div
                  className={`py-2 px-[15px] rounded-md flex gap-1.5 items-center border w-fit ${
                    stage?.isLive
                      ? 'bg-system-success-dark border-system-success'
                      : stage?.isSoon
                      ? 'bg-system-warning-dark border-system-warning'
                      : 'bg-system-error-dark border-system-error'
                  }`}
                >
                  <IconSvg
                    icon={stage?.isLive ? 'liveS' : stage?.isSoon ? 'comingSoonS' : 'finishedS'}
                  />
                  <span className="text-sm text-grayscale-100">
                    {stage?.isLive ? 'Live' : stage?.isSoon ? 'Soon' : 'Expired'}
                  </span>
                </div>

                {stage?.isLive ? (
                  <div className="text-xss text-grayscale-300">
                    <Countdown stage={stages[1]} />
                  </div>
                ) : stage?.isSoon ? (
                  <div className="text-xss text-grayscale-300">Remaining: 00d 00h 00m 00s</div>
                ) : null}
              </div>
            </div>
          ))}
        </div>

        <div className="mb-[25px]">
          <ProgressBar progress={30} size="XL" totalValue="1000" estimatedValue={0} />
        </div>

        <div className="flex items-end justify-between mb-[25px]">
          <div className="w-[287px] space-y-[13px]">
            <div className="text-lg text-grayscale-300">Mint Amount</div>

            <div className="flex gap-2.5 items-center flex-wrap">
              <div className="flex items-center gap-1.5">
                <IconSvg icon={iconNameM} />

                <div className="text-[24px] text-[#FAFAFA] font-semibold">4.5</div>
              </div>

              <div className="self-end text-sm text-grayscale-300">
                + 0.5 {networkCurrency} mint fee
              </div>
            </div>
          </div>

          <div className="space-y-[13px] w-fit flex flex-col items-center">
            <div className="text-lg text-grayscale-300">Points to earn</div>

            <div className="flex gap-2.5 items-center">
              <IconSvg icon="starM" />

              <div className="text-[24px] text-[#FAFAFA] font-semibold">110</div>
            </div>
          </div>

          <NumberInputWithMultiplier />
        </div>
        {selectedStage?.order === 1 && (
          <div className="flex justify-center mt-5">
            {isPhase1Loading ? (
              <SyncLoader color="#B73FFF" size={10} />
            ) : (
              <Button
                onClick={handleMintKeysPhase1}
                type="primary"
                size="m"
                classNames={`${
                  !address || !whitelistTypedP1[address] || phase1Hash ? 'cursor-not-allowed' : ''
                }`}
                disabled={!address || isPhase1Loading || !whitelistTypedP1[address] || phase1Hash} // Disable button when loading
              >
                Claim
              </Button>
            )}
          </div>
        )}

        {/*{selectedStage?.order === 2 && (*/}
        {/*  <div className="flex justify-center mt-5">*/}
        {/*    {isPhase2Loading ? (*/}
        {/*      <SyncLoader color="#B73FFF" size={10} />*/}
        {/*    ) : (*/}
        {/*      <Button*/}
        {/*        onClick={handleMintKeysPhase2}*/}
        {/*        type="primary"*/}
        {/*        classNames={`${*/}
        {/*          !address || !whitelistTyped[address] || phase2Hash ? 'cursor-not-allowed' : ''*/}
        {/*        }`}*/}
        {/*        size="m"*/}
        {/*        disabled={!address || isPhase2Loading || phase2Hash || !whitelistTyped[address]} // Disable button when loading*/}
        {/*      >*/}
        {/*        Claim*/}
        {/*      </Button>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </>
  );
};

export default MintStages;
